import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function TelescopicHandler() {
  return (
    <>
      <Helmet>
        <title>N010 Telescopic Handler | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N010 Telescopic Handler Training Course, including course objectives, learning outcomes, assessment, and certification options."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N010 Telescopic Handler</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Telescopic Handler Training Course aims to equip participants
              with the essential skills and knowledge required to operate a
              telescopic handler safely and effectively. This course ensures
              that operators meet the necessary industry standards and adhere to
              legal and regulatory requirements.
            </p>
            <br />
            <p>
              By the end of the course, participants will gain a thorough
              understanding of machine functionality, operational procedures,
              and best practices in various worksite conditions.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Industry overview, including common hazards and the role of a
                plant operator in ensuring safety and efficiency.
              </li>
              <li>
                Reading and interpreting technical manuals, manufacturer’s
                guidelines, and industry standards for telescopic handlers.
              </li>
              <li>
                Understanding machine components, including hydraulic systems,
                attachments, and stability mechanisms.
              </li>
              <li>
                Performing thorough pre-operational and safety checks to ensure
                the machine is fit for use.
              </li>
              <li>
                Effective operation of machine controls, including steering,
                load handling, and braking systems.
              </li>
              <li>
                Safe movement and maneuvering of the machine in confined spaces
                and on uneven terrain.
              </li>
              <li>
                Practical techniques for lifting, moving, and placing loads in
                compliance with industry guidelines.
              </li>
              <li>
                Handling various attachments and performing specific tasks, such
                as pallet handling, bucket operations, and fork lifting.
              </li>
              <li>
                Procedures for safe loading and unloading of transport vehicles,
                including securing loads.
              </li>
              <li>
                Transportation and storage requirements for telescopic handlers.
              </li>
              <li>
                Shutdown and maintenance practices to extend machine longevity
                and minimize downtime.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo rigorous assessments to evaluate their
              competency:
            </p>
            <br />
            <ul>
              <li>
                Theory Assessment: Includes open-ended questions and
                multiple-choice formats to test understanding of machine
                functionality, safety protocols, and best practices.
              </li>
              <br />
              <li>
                Practical Assessment: Demonstrates the ability to operate the
                telescopic handler safely, perform tasks, and adhere to
                site-specific requirements.
              </li>
            </ul>
            <p>
              Assessments are conducted by certified NPORS evaluators to ensure
              fairness and accuracy.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and assessments,
              candidates will receive NPORS certification. Certification details
              include:
            </p>
            <br />
            <ul>
              <li>
                Trained Operator Card: Valid for two years for individuals
                without an NVQ. Candidates must complete an (S)NVQ within this
                timeframe to qualify for the Competent Operator card.
              </li>
              <br />
              <li>
                Competent Operator Card: Valid for five years for individuals
                holding an NVQ. This certification also includes a CPD
                record/logbook for tracking professional development and
                operating hours.
              </li>
            </ul>
            <p>
              Certification ensures compliance with industry standards and
              enhances employability for plant operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N010 Telescopic Handler Training Course is a
              comprehensive program designed to prepare participants for
              real-world challenges. Combining theoretical instruction with
              hands-on experience, the course enables operators to handle
              equipment confidently and competently.
            </p>
            <br />
            <p>
              Whether you are seeking to start a career in plant operations or
              enhance your existing skills, this course is tailored to meet
              diverse needs and align with industry requirements.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
