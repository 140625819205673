import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function SlingerSignaller() {
  return (
    <>
      <Helmet>
        <title>N402 Slinger Signaller Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N402 Slinger Signaller Training Course, including course objectives, learning outcomes, assessment methods, and certification options."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N402 Slinger Signaller Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to prepare individuals for the role of a
              Slinger/Signaller, ensuring they can perform their duties
              proficiently and adhere to health, safety, and environmental
              regulations. Participants will gain the skills and knowledge
              required to select, secure, and guide loads in various lifting
              operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                The fundamentals of the lifting industry, including legal
                frameworks, industry guidelines, and operator responsibilities.
              </li>
              <li>
                Identifying and understanding lifting equipment and accessories,
                such as slings, shackles, hooks, and chains, and their proper
                applications.
              </li>
              <li>
                Reading and interpreting lifting plans, load charts, and
                manufacturer specifications to ensure safe lifting practices.
              </li>
              <li>
                Performing thorough pre-use inspections, including identifying
                wear and tear or damage in lifting accessories.
              </li>
              <li>
                Calculating the load weight, understanding dynamic and static
                loads, and ensuring proper load balance and center of gravity.
              </li>
              <li>
                Establishing and maintaining clear communication with crane
                operators using standard hand signals, radio, or verbal
                commands.
              </li>
              <li>
                Setting up safe exclusion zones around lifting operations and
                responding effectively to emergency situations.
              </li>
              <li>
                Managing environmental factors, such as wind speed, ground
                stability, and overhead obstructions, during lifting tasks.
              </li>
              <li>
                Ensuring proper attachment of lifting accessories, securing
                loads, and confirming stability before lifting.
              </li>
              <li>
                Safely guiding loads to their destinations while considering
                obstacles, load swing, and potential hazards.
              </li>
              <li>
                Recognizing the importance of proper storage and maintenance of
                lifting equipment after use.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              To achieve certification, candidates must successfully complete
              the following assessments:
            </p>
            <br />
            <ul>
              <li>
                Theory Assessment: A written test that includes multiple-choice
                and open-ended questions covering safety protocols, equipment
                use, and operational procedures.
              </li>
              <br />
              <li>
                Practical Assessment: Hands-on demonstration of skills,
                including load attachment, communication, and guiding loads
                under simulated working conditions.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, candidates will be eligible for the
              following certifications:
            </p>
            <br />
            <ul>
              <li>
                Trained Operator Card (2 years): For candidates who have not yet
                achieved an NVQ. This card allows them to operate as a trained
                operator while they complete the requisite NVQ qualification
                within 2 years.
              </li>
              <br />
              <li>
                Competent Operator Card (5 years): For candidates who already
                hold the requisite NVQ. This card includes a CPD record and
                logbook to track professional development and operational hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N402 Slinger Signaller Training Course is essential for
              anyone involved in lifting operations. It ensures participants are
              equipped with the knowledge and skills required to perform their
              duties safely and effectively, reducing risks and enhancing
              productivity in lifting environments. This course is suitable for
              novices and experienced individuals, with the course duration
              tailored to their prior knowledge and experience.
            </p>
            <br />
            <p>
              Participants will leave the course confident in their ability to
              manage lifting tasks, secure loads, and maintain communication
              with crane operators to ensure safe and efficient operations.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
