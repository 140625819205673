import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTelescopicHandler() {
  return (
    <>
      <Helmet>
        <title>A17 Telescopic Handler | Alpha Construction Training</title>
        <meta
          name="description"
          content="Gain a formal qualification in operating telescopic forklifts with the CPCS Telescopic Forklift (Telehandler) training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A17 Telescopic Handler</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Telescopic Forklift (Telehandler) training course is
              tailored to equip participants with the skills, knowledge, and
              confidence to operate telescopic handlers safely and efficiently.
            </p>
            <br />
            <p>
              This course is suitable for both beginners and experienced
              operators. Beginners will receive comprehensive training, while
              experienced operators can benefit from a shorter, focused course
              to formalize their expertise.
            </p>
            <br />
            <p>
              The primary objective is to ensure all participants can operate
              telescopic handlers in compliance with industry standards and
              health and safety regulations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Perform pre-start and post-operation checks to ensure the
                machine is in optimal condition.
              </li>
              <li>
                Safely operate the telescopic handler for a variety of lifting
                and material handling tasks.
              </li>
              <li>
                Accurately calculate load capacities to determine if a load is
                safe to lift.
              </li>
              <li>
                Understand the principles of machine stability and how
                environmental factors such as gradients and ground conditions
                can affect it.
              </li>
              <li>
                Learn correct techniques for loading, transporting, and storing
                materials to ensure efficiency and safety.
              </li>
              <li>
                Gain a deep understanding of the machine’s functionality,
                maintenance requirements, and safety features.
              </li>
              <li>
                Develop effective communication skills for working in a team
                using hand signals and site communication tools.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The assessment process combines classroom instruction with
              practical exercises to ensure participants are well-prepared for
              real-world operations. It includes:
            </p>
            <br />
            <ul>
              <li>
                A theoretical evaluation to test understanding of machine
                operations, safety protocols, and regulations.
              </li>
              <li>
                Practical assessments where learners demonstrate their
                competency in operating the telescopic handler.
              </li>
              <li>
                The CPCS technical test, conducted on the final day, which
                evaluates both practical and theoretical knowledge.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the CPCS technical tests,
              participants will receive the CPCS Red Trained Operator Card,
              which is valid for two years. This card signifies the operator’s
              foundational competence and readiness for on-site operations. To
              upgrade to the Blue CPCS Competence Card, participants must:
            </p>
            <br />
            <ul>
              <li>
                Complete an NVQ Level 2 in Plant Operations – Preparing and
                Operating Telescopic Handlers.
              </li>
              <li>
                Demonstrate consistent on-site operational experience within the
                two-year validity period of the Red Card.
              </li>
            </ul>
            <br />
            <p>
              The Blue CPCS Competence Card is renewable every five years and
              signifies the operator’s advanced skills and adherence to industry
              standards.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Telescopic Forklift (Telehandler) training course
              provides participants with the expertise needed to operate
              telescopic handlers confidently and professionally. Combining
              theoretical knowledge with hands-on practice, the course ensures
              participants are ready to handle the challenges of modern
              construction and material handling tasks.
            </p>
            <br />
            <p>
              By earning a CPCS certification, learners enhance their
              employability and career prospects in the construction industry.
              Join Alpha Construction Training today to gain industry-leading
              qualifications and advance your skills in telescopic handler
              operations.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
