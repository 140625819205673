import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigAttendant() {
  return (
    <>
      <Helmet>
        <title>A74 Piling Rig Attendant | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Piling Rig Attendant A74 Training Course to gain essential skills for working with piling rigs in the construction industry."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A74 Piling Rig Attendant</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Piling Rig Attendant A74 Course is designed to prepare
              individuals with the theoretical knowledge and hands-on practical
              skills needed to effectively support piling operations on
              construction sites. This course ensures participants are equipped
              to handle the challenges of working with piling rigs, maintaining
              safety standards, and adhering to industry best practices. It aims
              to build competency in assisting with the setup, operation, and
              maintenance of piling rigs, making you a valuable asset to any
              construction team.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                The fundamental principles of operating piling rigs and the role
                of an attendant in construction projects.
              </li>
              <li>
                Understanding ground conditions, soil types, and how they impact
                the efficiency and safety of piling operations.
              </li>
              <li>
                Best practices for ensuring safety on-site, including adherence
                to health and safety regulations.
              </li>
              <li>
                Assisting in the setup, dismantling, and transportation of
                piling rigs.
              </li>
              <li>
                Recognizing potential hazards and implementing effective
                solutions to mitigate risks during operations.
              </li>
              <li>
                Practical skills in monitoring and maintaining equipment
                performance during piling operations.
              </li>
              <li>
                Enhanced decision-making and problem-solving skills through
                real-world training scenarios.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The course features a structured assessment process to evaluate
              your theoretical understanding and practical proficiency.
              Assessments include:
            </p>
            <br />
            <ul>
              <li>
                Written tests to gauge your knowledge of safety protocols and
                operational guidelines.
              </li>
              <li>
                Practical exercises to demonstrate your skills in assisting with
                piling rig operations.
              </li>
              <li>
                Scenario-based evaluations to test your ability to identify and
                address real-world challenges.
              </li>
            </ul>
            <br />
            <p>
              These assessments ensure you are fully prepared to meet the
              demands of the construction industry as a competent Piling Rig
              Attendant.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the course and passing the
              assessments, you will be awarded the CPCS Red Trained Operator
              Card for Piling Rig Attendant A74. This certification is widely
              recognized across the construction industry and validates your
              expertise in supporting piling rig operations.
            </p>
            <br />
            <p>
              The Red Card is valid for two years, providing you with the
              opportunity to gain additional on-site experience. Participants
              are encouraged to complete the relevant NVQ to advance to the CPCS
              Blue Competent Operator Card, demonstrating a higher level of
              proficiency and opening doors to more opportunities.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig Attendant A74 Course is an essential training
              program for individuals looking to build a career in construction.
              By combining in-depth theoretical instruction with practical
              experience, this course equips you with the skills and confidence
              to excel as a Piling Rig Attendant. Whether you're entering the
              field or enhancing your current capabilities, this course offers
              the certification and knowledge necessary to thrive in the
              demanding construction industry.
            </p>
            <br />
            <p>
              Enroll today to gain the skills, certification, and expertise
              needed to support piling rig operations safely and effectively,
              ensuring your success on construction sites.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
