import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSRideOnRoller() {
  return (
    <>
      <Helmet>
        <title>A31 Ride-On Roller | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the operation of ride-on rollers with the CPCS Ride-On Roller (A31) training course at Alpha Construction Training. Gain formal qualifications to advance your career."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A31 Ride-On Roller</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Ride-On Roller (A31) Training Course is designed to
              provide participants with the skills, knowledge, and practical
              experience required to operate ride-on rollers safely and
              effectively. This course is ideal for both beginners and
              experienced operators who wish to formalize their skills with
              industry-recognized qualifications.
            </p>
            <br />
            <p>
              The objective is to ensure participants can meet the demands of
              modern construction sites, adhere to health and safety standards,
              and understand their responsibilities when operating ride-on
              rollers.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Perform pre-start, running, and shut-down inspections to ensure
                the roller is in optimal working condition.
              </li>
              <li>
                Configure the roller for safe and efficient travel, as well as
                effective compaction tasks.
              </li>
              <li>
                Identify potential site hazards, including ground conditions,
                and implement appropriate control measures.
              </li>
              <li>
                Understand the factors that contribute to the instability of
                ride-on rollers and how to mitigate risks during operation.
              </li>
              <li>
                Follow safety protocols when working near unsupported edges,
                trenches, and slopes.
              </li>
              <li>
                Execute proper loading and unloading techniques to and from a
                transporter, ensuring the safety of personnel and equipment.
              </li>
              <li>
                Learn effective communication methods using hand signals and
                other site-specific communication tools to coordinate with
                teams.
              </li>
              <li>
                Understand the fundamentals of soil compaction, including
                achieving the correct density and consistency for different
                types of materials.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The assessment process ensures that participants are competent in
              both theoretical knowledge and practical skills. It includes:
            </p>
            <br />
            <ul>
              <li>
                Classroom-based assessments covering operational principles,
                health and safety regulations, and best practices.
              </li>
              <li>
                Practical evaluations that require participants to demonstrate
                their ability to operate a ride-on roller effectively and
                safely.
              </li>
              <li>
                A CPCS technical test conducted on the final day of training,
                which evaluates overall competence through hands-on tasks and a
                knowledge-based test.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the CPCS technical tests, learners
              will receive the CPCS Red Trained Operator Card. This card is
              valid for two years and signifies the participant's foundational
              competence in operating ride-on rollers. To progress to the Blue
              CPCS Competence Card, participants must:
            </p>
            <br />
            <ul>
              <li>
                Complete an NVQ Level 2 in Plant Operations – Preparing and
                Operating Ride-On Rollers.
              </li>
              <li>
                Demonstrate consistent on-site operational experience within the
                two-year period.
              </li>
            </ul>
            <br />
            <p>
              The Blue Card is renewable every five years, requiring operators
              to maintain and update their skills to align with current industry
              standards.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Ride-On Roller (A31) Training Course is an essential
              program for those aiming to achieve professional qualifications
              and enhance their skills in operating ride-on rollers. Through a
              combination of theoretical knowledge and practical experience,
              participants will gain the confidence and expertise needed to
              perform roller operations efficiently and safely on modern
              construction sites.
            </p>
            <br />
            <p>
              This training is ideal for individuals looking to improve their
              career prospects in construction or material handling industries.
              With Alpha Construction Training, you’ll benefit from expert-led
              instruction, hands-on learning, and the opportunity to earn
              industry-recognized certification. Enroll today to take the next
              step in your professional development.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
