import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDragline() {
  return (
    <>
      <Helmet>
        <title>A05 Dragline | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enhance your operational skills with the CPCS Dragline A05 Training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A05 Dragline</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Dragline A05 Training Course is specifically designed to
              provide participants with the expertise required to operate
              dragline machinery effectively and responsibly. This course caters
              to individuals at all skill levels, from beginners seeking
              foundational knowledge to experienced operators looking to refine
              their skills.
            </p>
            <br />
            <p>
              By the end of the training, participants will possess a thorough
              understanding of dragline machine operations, safety regulations,
              and essential maintenance practices, ensuring they are
              well-prepared for practical work environments.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of dragline machinery, including its
                structure, components, and operational capabilities.
              </li>
              <li>
                Implementation of safety measures to prevent accidents and
                ensure a secure working environment.
              </li>
              <li>
                Conducting routine maintenance to ensure the machine's longevity
                and efficient performance.
              </li>
              <li>
                Identifying potential operational issues and applying corrective
                measures.
              </li>
              <li>
                Hands-on experience with operating dragline machinery in
                real-world scenarios.
              </li>
              <li>
                Adherence to legal and environmental guidelines related to the
                operation of dragline equipment.
              </li>
              <li>
                Coordination with team members to improve workflow and
                efficiency on the construction site.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The assessment process is structured to evaluate both theoretical
              knowledge and practical competence. This ensures participants have
              a well-rounded skillset for dragline machinery operations. The
              assessments include:
            </p>
            <br />
            <ul>
              <li>
                Written or verbal theory tests covering operational principles,
                safety practices, and regulations.
              </li>
              <li>
                Practical evaluations where participants demonstrate their
                ability to perform essential tasks such as setting up the
                machine, operating it in different conditions, and ensuring its
                safety.
              </li>
              <li>
                Observation of participants' adherence to safety standards,
                maintenance procedures, and operational efficiency.
              </li>
            </ul>
            <br />
            <p>
              Successful completion of the assessments qualifies participants
              for CPCS certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who pass the assessments will receive the CPCS Red
              Trained Operator Card, which validates their proficiency in
              operating dragline machinery. This certification is valid for two
              years and is recognized as an essential qualification in the
              construction industry.
            </p>
            <br />
            <p>
              To upgrade to the CPCS Blue Competence Card, participants must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period of the Red Card. The Blue Card demonstrates
              practical competence and is a critical step for long-term career
              advancement in construction.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Dragline A05 Training Course is an intensive program that
              combines theoretical instruction with practical application,
              equipping participants with the skills and knowledge needed to
              excel in their roles. This training prepares learners not only for
              the CPCS certification tests but also for the operational demands
              of the construction industry.
            </p>
            <br />
            <p>
              Whether you are beginning your career or aiming to upgrade your
              expertise, this course offers the tools and qualifications
              necessary for success. Join Alpha Construction Training and take a
              definitive step toward becoming a certified dragline operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
