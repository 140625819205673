import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSkidSteerToolCarrier() {
  return (
    <>
      <Helmet>
        <title>D92 Skid Steer Tool Carrier | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Skid Steer Tool Carrier D92 Training Course to master skid steer loader operations and earn your CPCS Red Trained Operator Card."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>D92 Skid Steer Tool Carrier</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embarking on the CPCS Skid Steer Tool Carrier D92 Course is a
              pivotal step towards enhancing your proficiency in handling skid
              steer loaders. These compact, versatile machines play a critical
              role in construction, landscaping, and agricultural operations.
              Our course is meticulously designed to provide participants with a
              robust understanding of skid steer functionality, operational
              techniques, and safety measures to ensure efficient performance in
              diverse work environments.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the manufacturer’s guidelines and workplace safety
                requirements, including personal protective equipment (PPE) and
                risk assessment strategies.
              </li>
              <li>
                Proficient operation of skid steer loaders, including navigating
                confined spaces and challenging terrains.
              </li>
              <li>
                Performing pre-use checks, routine maintenance, and
                troubleshooting common mechanical issues to ensure equipment
                reliability.
              </li>
              <li>
                Safely handling loads, including balancing, stacking, and
                transporting materials in various site conditions.
              </li>
              <li>
                Adhering to safe loading and unloading practices on trailers and
                other transport vehicles.
              </li>
              <li>
                Techniques for minimizing environmental impact and maintaining
                operational efficiency on worksites.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course integrates both theoretical and practical assessments
              to ensure comprehensive learning. The theoretical segment
              evaluates your knowledge of health and safety regulations, machine
              functionality, and best practices. The practical assessment
              involves hands-on tasks such as maneuvering, lifting, and loading
              with precision. These evaluations are designed to simulate
              real-world scenarios, providing participants with the confidence
              and competence to operate skid steer loaders safely and
              effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the course and passing the CPCS
              assessments, you will be awarded the CPCS Red Trained Operator
              Card. This certification is highly regarded in the construction
              industry and demonstrates your proficiency in operating skid steer
              loaders. The Red Card is valid for two years, after which you can
              upgrade to the CPCS Blue Competent Operator Card by completing the
              relevant NVQ.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Skid Steer Tool Carrier D92 Course is a comprehensive
              training program that combines theoretical knowledge with
              practical skills to prepare participants for the demands of the
              construction industry. This course emphasizes safe operational
              practices, efficient handling of materials, and adherence to
              environmental and workplace standards. By completing this course,
              you’ll not only be prepared to pass the CPCS test but also gain
              valuable skills that can enhance your employability and career
              growth in the construction and related industries.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
