import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator360Above10T() {
  return (
    <>
      <Helmet>
        <title>
          A59 Excavator 360º Above 10 Tonne | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Excavator 360 Above 10 Tonne Training course to gain essential skills and certification for operating 360-degree excavators above 10 tonnes."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A59 Excavator 360º Above 10 Tonne</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This 360 Excavator CPCS training course is aimed at individuals
              seeking to obtain formal qualifications for operating 360-degree
              excavators above 10 tonnes. The course combines theory and
              practical training to equip participants with critical skills for
              safe and effective operation. A learner analysis is conducted to
              tailor the duration of training based on individual experience,
              ensuring both novice and experienced operators achieve competency.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Operate 360-degree tracked excavators safely and efficiently in
                a range of site conditions.
              </li>
              <li>
                Attach, operate, and maintain ancillary equipment for specific
                tasks such as digging, grading, and trenching.
              </li>
              <li>
                Position and site the machine for optimal performance while
                ensuring stability and safety.
              </li>
              <li>
                Perform routine servicing procedures and minor adjustments to
                maintain machinery functionality.
              </li>
              <li>
                Understand machine capabilities and limitations for different
                operational tasks.
              </li>
              <li>
                Implement safety protocols to reduce risks and comply with
                industry regulations.
              </li>
              <li>
                Accurately interpret information from level pegs, profiles, and
                technical site documentation.
              </li>
              <li>
                Utilize laser equipment and other tools to achieve precise
                grading and leveling.
              </li>
              <li>
                Troubleshoot and resolve common operational challenges on-site.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment process integrates classroom-based learning with
              hands-on practical exercises. Participants are tested on their
              theoretical knowledge and practical competencies through the CPCS
              technical tests. This includes demonstrating the ability to
              operate equipment safely, interpret site specifications, and
              adhere to industry standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS technical tests,
              participants are awarded the Red CPCS Trained Operator Card, valid
              for two years. This certification verifies the ability to operate
              360-degree excavators above 10 tonnes. During the two-year
              validity period, operators must complete an NVQ Level 2 in Plant
              Operations, focusing on the preparation and operation of
              excavators. Successful NVQ completion allows application for the
              CPCS Blue Competence Card, which is renewable every five years and
              recognized across the construction industry as a mark of advanced
              competency.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Excavator 360 Above 10 Tonne Training Course provides
              comprehensive training for operators to gain essential skills and
              industry-recognized certification. This course is suitable for
              individuals new to machinery operation as well as experienced
              operators seeking formal qualifications. Alpha Construction
              Training ensures participants receive expert guidance, enabling
              them to excel in the construction industry and operate with
              confidence in challenging environments.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
