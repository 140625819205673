import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSLoaderSecurer() {
  return (
    <>
      <Helmet>
        <title>A49 Loader Secure | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Loader Securer A49 training course at Alpha Construction Training to gain expertise in loading and securing mobile plant safely and effectively."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A49 Loader Securer</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is tailored for individuals seeking
              formal qualifications in loading and unloading mobile plant. It
              prepares participants to safely and efficiently handle loading and
              securing tasks, adhering to industry regulations and standards.
              The course emphasizes minimizing risks, ensuring safety, and
              maximizing operational efficiency, making it a vital certification
              for those involved in plant transportation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the role and responsibilities of a loader/securer in
                the industry, including the importance of effective
                communication.
              </li>
              <li>
                Identify types of transporters, their purposes, and principal
                components, such as ramps, winches, and securing mechanisms.
              </li>
              <li>
                Conform to manufacturer's requirements and relevant legislation,
                including health and safety standards and transportation laws.
              </li>
              <li>
                Conduct pre-use checks on transporters and prime movers to
                ensure their readiness for operations and safety compliance.
              </li>
              <li>
                Set up for travel on sites and public highways, addressing
                site-specific hazards and road safety regulations.
              </li>
              <li>
                Prepare the area and equipment for loading and unloading duties,
                including assessing ground conditions and equipment stability.
              </li>
              <li>
                Securely load and position various plant items on and off a
                trailer, with a focus on maintaining load balance and integrity.
              </li>
              <li>
                Ensure compliance with communication procedures during
                operations, utilizing signals and two-way radios effectively.
              </li>
              <li>
                Explain considerations and legislative requirements for
                transporting loads on public highways, including weight limits
                and signage.
              </li>
              <li>
                Plan and execute safe routes and loading procedures on public
                highways, addressing route-specific challenges and weather
                conditions.
              </li>
              <li>
                Shut down and secure equipment post-operation to prevent
                unauthorized use and maintain safety standards.
              </li>
              <li>
                Understand the Road Traffic Act and its implications for plant
                operations, including legal responsibilities and penalties for
                non-compliance.
              </li>
              <li>
                Develop a thorough understanding of risk assessment practices
                related to loading and securing mobile plant.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves a mix of classroom instruction and practical
              exercises, culminating in a one-day assessment through CPCS
              technical tests. These tests evaluate participants' skills in
              areas such as equipment inspection, safe loading techniques, and
              adherence to legislative requirements. Participants will also
              demonstrate their understanding of risk assessment, communication
              procedures, and route planning.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To upgrade to the Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within the two-year timeframe. The Blue Card is
              renewable every five years, ensuring long-term compliance and
              industry recognition for skilled operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Loader Securer A49 course is ideal for individuals aiming
              to gain certification and skills in loading and securing mobile
              plant. It provides comprehensive training on safe practices,
              legislative compliance, and operational efficiency.
            </p>
            <br />
            <p>
              By completing this course, participants enhance their
              employability and meet industry requirements, paving the way for
              career advancement in the construction sector. This training is
              invaluable for professionals involved in plant operations, site
              logistics, and transportation.
            </p>
            <br />
            <p>
              Enroll today and take the first step toward becoming a certified
              loader/securer. With Alpha Construction Training, you’ll gain the
              skills and knowledge needed to excel in your role.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
