import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSoilLandfillCompactor() {
  return (
    <>
      <Helmet>
        <title>
          A32 Soil / Landfill Compactor | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in our CPCS Soil / Landfill Compactor (A32) Training Course to master the operation of soil and landfill compactors with certification upon completion."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>
            A32 Soil / Landfill Compactor
            <br />
          </h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS A32 Soil / Landfill Compactor course is designed to equip
              participants with the skills and knowledge needed to operate soil
              and landfill compactors safely and efficiently. Whether you are a
              beginner or an experienced operator, the course caters to your
              specific needs through tailored learning plans. Our program
              ensures compliance with industry standards, enhancing workplace
              safety and operational efficiency.
            </p>
            <br />
            <p>
              By the end of the course, learners will have a comprehensive
              understanding of compactor operations, including safety protocols,
              pre-use checks, compaction principles, and the efficient handling
              of materials on-site.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                The role and responsibilities of a plant operator in
                construction and waste management.
              </li>
              <li>
                Comprehensive knowledge of the machine's components, functions,
                and terminology.
              </li>
              <li>
                Compliance with health and safety legislation, manufacturer’s
                guidelines, and operational procedures.
              </li>
              <li>
                Performing pre-use checks and setting up the compactor for
                transport and operation.
              </li>
              <li>
                Effective operation over various terrains, including rough
                ground, slopes, and confined areas.
              </li>
              <li>
                Techniques for compacting waste materials and leveling soils for
                covering or construction purposes.
              </li>
              <li>
                The principles of soil and waste material compaction for optimal
                efficiency.
              </li>
              <li>
                Safe procedures for shutting down, parking, and securing the
                machine.
              </li>
              <li>
                Correct loading and unloading techniques for transporting the
                machine.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment process involves both practical and theoretical
              evaluation to ensure learners meet the required competency
              standards. It includes:
            </p>
            <ul>
              <li>
                A practical test where learners demonstrate their ability to
                operate the compactor safely and effectively.
              </li>
              <li>
                A theoretical test covering health and safety regulations,
                machine operations, and industry best practices.
              </li>
            </ul>
            <p>
              Assessments are conducted under CPCS guidelines to ensure learners
              are well-prepared for real-world operational challenges.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              On successful completion of the course and assessments, candidates
              will be awarded the Red CPCS Trained Operator Card, which is valid
              for two years. During this time, operators must complete an NVQ
              Level 2 in Plant Operations to progress to the Blue CPCS
              Competence Card. This advanced certification is renewable every
              five years and serves as a testament to the operator's skills and
              experience.
            </p>
            <br />
            <p>
              The CPCS card is widely recognized within the construction and
              waste management industries, enhancing employment opportunities
              and career advancement.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS A32 Soil / Landfill Compactor course is ideal for anyone
              looking to gain or enhance their qualifications in operating soil
              and landfill compactors. The course combines classroom-based
              learning with practical exercises to ensure a thorough
              understanding of machine operations and safety. Candidates must
              have completed the Operatives Health, Safety, and Environment Test
              within the last two years and possess a strong command of spoken
              and written English to participate effectively.
            </p>
            <br />
            <p>
              Participants are also required to bring their own personal
              protective equipment (PPE), including safety boots and
              high-visibility clothing, for the practical elements of the
              training.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
