import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTunnellingLocomotives() {
  return (
    <>
      <Helmet>
        <title>A67 Tunnelling Locomotives | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Tunnelling Locomotives (A67) Training Course to gain expertise in tunnel locomotive operations, safety protocols, and maintenance."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A67 Tunnelling Locomotives</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is meticulously designed to equip individuals with the
              critical skills and knowledge required for the safe and effective
              operation of tunnelling locomotives. The training focuses on
              fostering competency in equipment handling, adherence to safety
              regulations, and execution of operational procedures. By blending
              theoretical knowledge with practical experience, participants are
              fully prepared to take on roles in challenging tunnelling
              environments, ensuring operational safety and efficiency.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of operational safety standards for
                tunnelling locomotives, including pre-operational checks and
                emergency shutdown procedures.
              </li>
              <li>
                Basics of routine and preventative maintenance to ensure
                locomotive functionality and longevity.
              </li>
              <li>
                Identifying and mitigating hazards in tunnel environments, such
                as low visibility, restricted movement areas, and potential
                structural weaknesses.
              </li>
              <li>
                Learning about load types, track layouts, signaling systems, and
                how to interpret various signals, including traffic lights and
                hand signals.
              </li>
              <li>
                Developing skills to navigate confined spaces, steep gradients,
                and other challenging conditions while maintaining full control
                of the locomotive.
              </li>
              <li>
                Emergency response procedures, including evacuation techniques
                and managing incidents within a confined space.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course consists of two intensive training days, during which
              learners participate in both classroom-based theoretical
              instruction and practical sessions in a controlled environment.
              These sessions cover all aspects of tunnelling locomotive
              operation and safety. The assessment includes a CPCS technical
              test, which evaluates the participant’s ability to operate the
              equipment effectively and safely. The technical test consists of a
              theoretical multiple-choice exam and a practical evaluation of
              equipment operation.
            </p>
            <br />
            <p>
              To ensure a comprehensive understanding of the material, learners
              will also receive hands-on guidance and real-world scenarios to
              test their decision-making skills under simulated conditions.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS technical test, learners
              will be awarded the CPCS A67 Tunnelling Locomotives certification.
              This certification is a recognized qualification within the
              construction and tunnelling sectors, demonstrating the holder's
              ability to operate tunnelling locomotives safely and efficiently.
              Certified individuals will be well-equipped to pursue employment
              opportunities as trained operators in the tunnelling and
              construction industries.
            </p>
            <br />
            <p>
              The CPCS A67 certification is valid for a specified period and
              requires periodic renewal through refresher training and
              assessments, ensuring that certified individuals remain updated
              with the latest safety standards and operational techniques.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course is ideal for individuals looking to specialize in
              tunnelling locomotive operations within the construction and
              tunnelling industries. Participants must meet certain eligibility
              criteria to enroll. Specifically, candidates must have passed the
              Operatives Health, Safety, and Environment Test within the last
              two years prior to the course start date. This requirement ensures
              that all participants have a foundational understanding of health
              and safety practices in construction environments.
            </p>
            <br />
            <p>
              Additionally, a strong command of both spoken and written English
              is required. This ensures participants can fully engage with the
              course materials, understand instructions, and perform well in
              both the theoretical and practical assessments. The program also
              covers essential elements such as tunnelling locomotive
              maintenance, safety protocols, and compliance with current
              industry regulations.
            </p>
            <br />
            <p>
              By the end of the training, participants will have gained a
              well-rounded understanding of tunnelling locomotive operations,
              ensuring they are equipped to handle the challenges of real-world
              tunnelling projects with confidence.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
