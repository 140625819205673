import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function Screener() {
  return (
    <>
      <Helmet>
        <title>N208 Screener | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N208 Screener Training Course, including key skills, detailed assessment procedures, and certification options for Red and Blue NPORS cards."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N208 Screener</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The N208 Screener Training Course is designed to equip operators
              with a comprehensive understanding of screener operations,
              focusing on safety, operational proficiency, and adherence to
              industry standards. This course provides hands-on training and
              theoretical knowledge to help participants confidently and
              effectively operate screeners in various work environments. It
              also emphasizes environmental responsibility and efficiency,
              ensuring operators are prepared for industry demands.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Introduction to the screener's purpose, different types, and
                their applications in material processing, including an overview
                of their components and features.
              </li>
              <li>
                Understanding screener controls and their functions, along with
                proper use of Personal Protective Equipment (PPE) to ensure
                safety during operation.
              </li>
              <li>
                Conducting thorough pre-start and running checks to identify
                potential mechanical issues and ensure safe operation.
              </li>
              <li>
                Reviewing the manufacturer’s handbook to understand specific
                machine guidelines, operational limitations, and recommended
                practices.
              </li>
              <li>
                Operating the screener safely, efficiently, and in compliance
                with NPORS and industry best practices, including loading and
                unloading procedures.
              </li>
              <li>
                Managing site-specific hazards, maintaining environmental
                standards, and ensuring proper waste management during
                operations.
              </li>
              <li>
                Learning emergency procedures, including how to respond to
                mechanical failures, equipment malfunctions, and unsafe
                situations during operation.
              </li>
              <li>
                Properly parking and shutting down the screener to ensure the
                machine remains secure and operational for future use.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              To successfully complete the NPORS N208 Screener Training Course,
              candidates must demonstrate both theoretical knowledge and
              practical skills through rigorous assessments:
            </p>
            <br />
            <ul>
              <li>
                Theory Assessment: A multiple-choice test consisting of 25
                questions that evaluate the candidate's understanding of
                screener operation, safety protocols, and environmental
                considerations.
              </li>
              <br />
              <li>
                Practical Assessment: Hands-on evaluation where candidates must
                demonstrate their ability to operate the screener safely and
                efficiently, perform pre-start checks, and manage tasks such as
                material processing and emergency responses.
              </li>
              <br />
              <li>
                Feedback and Guidance: Candidates receive personalized feedback
                on their performance, highlighting areas of strength and
                opportunities for improvement.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and assessments,
              candidates will be awarded an NPORS card. There are two main
              options for certification:
            </p>
            <br />
            <ul>
              <li>
                NPORS Traditional Card: This card is valid for 3 to 5 years and
                is internationally recognized, making it ideal for operators
                working in industries outside of mainstream construction.
              </li>
              <br />
              <li>
                NPORS CSCS Card: This card is embossed with CSCS (Construction
                Skills Certification Scheme) branding and is widely accepted
                within the UK construction industry. Candidates must have
                completed a valid Health, Safety & Environment (HS&E) test
                within the last 2 years to qualify for this card.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N208 Screener Training Course combines theoretical
              learning and practical exercises to ensure candidates are well
              prepared for real-world operations. Key aspects of the course
              include:
            </p>
            <br />
            <ul>
              <li>
                NPORS CSCS Red Trained Operator Card (2 years): Awarded to
                operators who successfully complete the course and testing
                requirements. This card serves as an entry-level qualification,
                enabling operators to gain hands-on experience in their field.
              </li>
              <br />
              <li>
                NPORS CSCS Blue Competent Operator Card (5 years): Issued to
                operators who complete the relevant NVQ in addition to their
                NPORS testing. This card signifies a high level of competency
                and professionalism.
              </li>
              <br />
            </ul>
            <p>
              By the end of the course, participants will have gained the skills
              and confidence needed to operate a screener safely, efficiently,
              and in compliance with legal and environmental requirements.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
