import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSIndustrialForklift() {
  return (
    <>
      <Helmet>
        <title>
          A16 CPCS Industrial Forklift | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Learn about CPCS Industrial Forklift (A16) training with Alpha Construction Training. Gain practical skills, industry compliance, and CPCS certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A16 CPCS Industrial Forklift</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides comprehensive training to ensure participants
              acquire the skills and knowledge required to safely and
              efficiently operate an industrial forklift. The training is
              tailored to accommodate both novice and experienced operators,
              focusing on practical techniques, compliance with safety
              standards, and effective load handling.
            </p>
            <br />
            <p>
              Designed to align with CPCS standards, this course prepares
              participants for the technical challenges of industrial forklift
              operations while enhancing their employability in the construction
              and logistics industries.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Detailed understanding of principal components, operational
                controls, and terminologies related to industrial forklifts.
              </li>
              <li>
                Knowledge of manufacturers’ requirements, operator handbooks,
                and adherence to safety regulations and legislations.
              </li>
              <li>
                Proficiency in conducting pre-use and maintenance checks to
                ensure safe and efficient forklift operation.
              </li>
              <li>
                Techniques for navigating confined spaces and challenging
                environments, with or without loads.
              </li>
              <li>
                Accurate lifting, transferring, and placement of loads,
                including at maximum working height.
              </li>
              <li>
                Safe methods for loading and unloading vehicles while
                maintaining a clean and organized work area.
              </li>
              <li>
                Identification and mitigation of hazards, including overhead and
                underground services.
              </li>
              <li>
                Proper shutdown and storage procedures to maintain equipment
                longevity and safety.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo a combination of theoretical and
              practical assessments. The theoretical evaluation involves a
              classroom-based test covering safety principles, forklift
              operation standards, and load management techniques. The practical
              assessment focuses on real-world scenarios, testing participants’
              abilities to handle industrial forklifts safely and effectively in
              a variety of environments.
            </p>
            <br />
            <p>
              The CPCS technical test, conducted over one day, determines the
              readiness and competence of the participants, ensuring they meet
              industry standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the CPCS technical tests, candidates
              will be awarded the Red CPCS Trained Operator Card. This card is
              valid for two years and serves as proof of initial training. To
              transition to the Blue CPCS Competence Card, valid for five years,
              participants must complete an NVQ Level 2 in Plant Operations
              within the two-year period.
            </p>
            <br />
            <p>
              CPCS certification is widely recognized across the UK, opening
              opportunities for roles in logistics, construction, and industrial
              sectors requiring forklift operations.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This A16 CPCS Industrial Forklift course offers an integrated
              approach to learning, combining theoretical insights with hands-on
              practical experience. Participants will develop a deep
              understanding of forklift operations and safety standards,
              equipping them for real-world challenges.
            </p>
            <br />
            <p>
              Led by expert instructors and supported by state-of-the-art
              training facilities, this course ensures participants gain the
              confidence and competence required for successful careers in
              industrial forklift operations.
            </p>
            <br />
            <p>
              Join Alpha Construction Training to elevate your skills and
              achieve a recognized CPCS certification that sets you apart in the
              competitive job market.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
