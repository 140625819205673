import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function CPCSCrawlerCrane() {
  return (
    <>
      <Helmet>
        <title>A02 CPCS Crawler Crane | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in the A02 Crawler Crane training course at Alpha Construction Training. Gain expertise in crawler crane operations, safety, and receive CPCS certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A02 CPCS Crawler Crane</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is meticulously designed to provide aspiring operators
              with the essential skills and knowledge to safely and competently
              operate a crawler crane in various construction environments. It
              ensures a comprehensive understanding of operational techniques
              and safety protocols.
            </p>
            <br />
            <p>
              CPCS certification gained through this course is a recognized
              qualification in the construction industry, enhancing your
              employability and career prospects. Whether you are a beginner or
              an experienced operator seeking formal certification, this course
              caters to all skill levels.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                An understanding of the roles and responsibilities of a crawler
                crane operator, including adherence to health and safety
                regulations.
              </li>
              <li>
                Mastery of operational controls and functions of crawler cranes,
                including maneuvering, load handling, and working within various
                environments such as tight spaces and uneven ground.
              </li>
              <li>
                Safety protocols for preventing accidents, recognizing crane
                signals, and understanding factors like wind influence, jib
                length, shock loading, and safe working loads (SWL).
              </li>
              <li>
                Techniques for handling, lifting, and transferring loads safely
                and efficiently, minimizing risks to personnel and equipment.
              </li>
              <li>
                Maintenance procedures, including routine checks, minor
                adjustments, and identifying potential hazards before
                operations.
              </li>
              <li>
                Knowledge of the lifting plan process and the role of a crane
                operator in adhering to site-specific requirements.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undertake a rigorous assessment, including a
              theory test and practical evaluation. The theory test covers
              safety procedures, operational principles, and load-handling
              techniques, focusing on real-world scenarios.
            </p>
            <br />
            <p>
              The practical assessment evaluates your ability to operate a
              crawler crane under supervised conditions, including setup,
              maneuvering, and lifting operations. This ensures that you have
              the competency to handle equipment in a professional and safe
              manner.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              On successful completion of the course, you will receive the CPCS
              certification. This includes a Red Trained Operator card valid for
              2 years. To progress to the Blue Competent Operator card, you will
              need to complete a relevant NVQ and demonstrate on-site
              experience. The Blue card is valid for five years and recognized
              across the UK.
            </p>
            <br />
            <p>
              CPCS certification is an industry benchmark, confirming your
              competency and safety awareness in operating crawler cranes. It
              ensures you are fully prepared for a wide range of construction
              roles, from small-scale projects to major infrastructure
              developments.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This 10-day course offers a blend of theoretical and practical
              training, equipping you with the skills and confidence to operate
              a crawler crane in diverse construction environments. The training
              is delivered by experienced instructors using modern equipment and
              facilities.
            </p>
            <br />
            <p>
              Key topics include operational controls, load-handling techniques,
              safety protocols, and the role of the operator in team operations.
              By the end of the course, you will be able to carry out tasks
              efficiently and safely, meeting industry standards.
            </p>
            <br />
            <p>
              The A02 Crawler Crane course is your gateway to a rewarding career
              in construction, offering unparalleled learning experiences and a
              path to certification recognized nationwide.
            </p>
          </div>

          <div className="contact-us-section">
            <Link to="/contactus" className="contact-us-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
