import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMEWPBoom() {
  return (
    <>
      <Helmet>
        <title>A26 MEWP Boom | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the skills to operate MEWPs with the CPCS Mobile Elevating Work Platform – Boom A26 training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A26 MEWP Boom</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Mobile Elevating Work Platform – Boom A26 Training Course
              is tailored to provide participants with the critical skills and
              knowledge required to safely and efficiently operate mobile
              elevating work platforms (MEWPs). The course emphasizes safety,
              operational proficiency, and adherence to industry standards.
            </p>
            <br />
            <p>
              This program accommodates both beginners seeking foundational
              training and seasoned operators looking to refine their expertise
              in MEWP operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of mobile elevating work platform
                components and their functions.
              </li>
              <li>
                Application of operational safety measures to minimize risks on
                the worksite.
              </li>
              <li>
                Conducting routine pre-start inspections and essential
                maintenance checks to ensure equipment reliability.
              </li>
              <li>
                Proficiency in executing emergency procedures, including rescue
                operations and fault management.
              </li>
              <li>
                Hands-on experience in maneuvering and operating MEWPs in a
                variety of scenarios.
              </li>
              <li>
                Familiarity with relevant legislation, industry standards, and
                safety protocols that govern the use of MEWPs.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Assessment for the CPCS Mobile Elevating Work Platform – Boom A26
              Training Course is conducted through a structured process to
              evaluate participants' theoretical knowledge and practical
              competence. This includes:
            </p>
            <br />
            <ul>
              <li>
                Classroom-based teaching, covering the operational principles,
                safety standards, and legal requirements of MEWP usage.
              </li>
              <li>
                Practical exercises focused on operating MEWPs, performing
                safety checks, and managing real-world challenges.
              </li>
              <li>
                Final CPCS technical tests, consisting of a theory exam and
                hands-on evaluation to measure overall proficiency.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, participants
              will be awarded the CPCS Red Trained Operator Card. This
              certification is valid for two years and signifies initial
              training and competency in MEWP operations.
            </p>
            <br />
            <p>
              To upgrade to the Blue CPCS Competence Card, participants are
              required to complete an NVQ Level 2 in Plant Operations during the
              two-year validity period. The Blue Card, renewable every five
              years, is a testament to advanced expertise and is widely
              recognized in the construction industry.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Mobile Elevating Work Platform – Boom A26 Training Course
              combines in-depth theoretical knowledge with practical hands-on
              training to prepare participants for safe and efficient MEWP
              operations. The course is designed to meet the needs of modern
              construction and maintenance environments.
            </p>
            <br />
            <p>
              With a strong focus on safety, compliance, and operational
              proficiency, this course is ideal for individuals seeking
              industry-recognized certification and career advancement. Enroll
              at Alpha Construction Training today to elevate your skills and
              secure your professional future in MEWP operations.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
