import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSOverheadCrane() {
  return (
    <>
      <Helmet>
        <title>A64 Overhead Crane | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Overhead Travelling Crane (A64) Training Course to learn safe operation and maintenance of overhead cranes, ensuring compliance with industry standards."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A64 Overhead Crane</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This comprehensive course is designed for individuals with little
              or no prior experience in operating an Overhead Travelling Crane.
              The objective is to provide the foundational skills, knowledge,
              and confidence required to operate overhead cranes safely and
              effectively. The course emphasizes compliance with industry
              regulations, understanding operational limitations, and applying
              best practices in diverse scenarios. Participants will gain the
              ability to manage crane operations, conduct inspections, and
              maintain equipment to ensure safety and efficiency.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the roles and responsibilities of personnel
                involved in lifting operations and ensuring compliance with
                legal requirements.
              </li>
              <li>
                Gaining in-depth knowledge of crane types, applications, and
                operational limitations.
              </li>
              <li>
                Mastering the terminology and technical aspects of overhead
                cranes, including load capacities and equipment specifications.
              </li>
              <li>
                Preparing and interpreting critical documentation such as
                lifting plans, method statements, and risk assessments.
              </li>
              <li>
                Accurately calculating loads, determining the center of gravity,
                and applying safe lifting techniques.
              </li>
              <li>
                Assessing the suitability of lifting accessories, including
                their safe working loads (SWL) and working load limits (WLL).
              </li>
              <li>
                Familiarizing with signaling systems, effective communication
                methods, and coordination with ground staff.
              </li>
              <li>
                Performing daily crane inspections, identifying faults, and
                reporting issues following industry protocols.
              </li>
              <li>
                Operating overhead cranes in diverse environments and
                controlling load movement with precision.
              </li>
              <li>
                Managing emergency scenarios, including equipment failures and
                unplanned events, ensuring safety at all times.
              </li>
              <li>
                Gaining hands-on experience with power units, control systems,
                and safety devices to maintain operational efficiency.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment component includes a structured combination of
              theoretical evaluation and practical testing. Over two days, the
              course integrates classroom-based instruction covering operational
              theory, equipment specifications, and safety standards. Practical
              sessions provide hands-on experience in operating overhead cranes,
              ensuring participants understand real-world scenarios.
            </p>
            <br />
            <p>
              The final day focuses on the CPCS technical test, which evaluates
              participants' understanding of the theoretical material and their
              ability to apply it practically. This test consists of a
              multiple-choice theoretical exam and a practical examination of
              operational skills, ensuring learners are fully prepared for
              industry challenges.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon passing the CPCS technical tests, learners will be awarded a
              Red CPCS Trained Operator Card. This certification is valid for
              two years and provides proof of competency in overhead crane
              operations. To progress further, learners are required to complete
              an NVQ Level 2 in Plant Operations - Preparing and Operating
              Overhead Cranes. Successful completion of the NVQ will allow
              learners to upgrade to a Blue CPCS Competence Card, which is valid
              for five years and renewable.
            </p>
            <br />
            <p>
              The Blue Card represents advanced proficiency in overhead crane
              operations and demonstrates a commitment to safety, efficiency,
              and ongoing professional development. Renewal involves updating
              skills and knowledge to align with current industry standards and
              regulations.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course is open to individuals who have passed the Operatives
              Health, Safety, and Environment Test within the last two years.
              This pre-requisite ensures participants have the foundational
              understanding necessary to engage with the course content and
              adhere to safety regulations. A good command of spoken and written
              English is essential for active participation in classroom
              discussions, practical sessions, and assessments.
            </p>
            <br />
            <p>
              The program covers the theoretical and practical aspects of
              overhead crane operations, including compliance with industry
              standards, risk assessment, and maintenance protocols. By the end
              of the training, participants will have gained a well-rounded
              understanding of overhead crane operations, enabling them to
              contribute effectively in professional settings.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
