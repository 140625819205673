import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSConveyorPump() {
  return (
    <>
      <Helmet>
        <title>A75 Conveyor Pump | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Conveyor Pump (A75) Training Course to master the operation, maintenance, and safety protocols for conveyor pumps. Gain CPCS certification and enhance your career in the construction industry."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A75 Conveyor Pump</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This A75 Conveyor Pump Course is designed to equip operators with
              the technical knowledge and practical skills needed to
              proficiently operate, maintain, and manage conveyor pumps in a
              variety of construction environments. The course focuses on
              safety, efficiency, and best practices to ensure compliance with
              industry regulations while enhancing productivity on-site.
            </p>
            <br />
            <p>
              By the end of the course, participants will have the confidence
              and expertise required to operate conveyor pumps effectively,
              troubleshoot common issues, and contribute to the smooth running
              of construction projects.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                An in-depth understanding of the operational mechanics of
                conveyor pumps, including the components, functions, and types
                of pumps commonly used in the construction industry.
              </li>
              <li>
                Best practices for safely operating conveyor pumps in various
                site conditions, including navigating potential hazards and
                implementing effective risk mitigation strategies.
              </li>
              <li>
                Maintenance procedures, including pre-use checks, routine
                servicing, and troubleshooting techniques to ensure the
                equipment remains in optimal condition.
              </li>
              <li>
                How to set up, configure, and maneuver conveyor pumps,
                emphasizing proper alignment and secure anchoring of pipes and
                lines for efficient material delivery.
              </li>
              <li>
                Procedures for cleaning the equipment after use, managing waste
                responsibly, and ensuring all required documentation is
                completed accurately.
              </li>
              <li>
                Practical techniques for handling emergency situations,
                including pump failures and unexpected site conditions, to
                maintain safety and operational continuity.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of theoretical and practical
              assessments designed to evaluate each participant's competency in
              conveyor pump operation. Key components of the assessment include:
            </p>
            <ul>
              <li>
                Theory Test: A written multiple-choice assessment covering
                safety protocols, operational principles, and troubleshooting
                techniques.
              </li>
              <br />
              <li>
                Practical Test: A hands-on evaluation where participants
                demonstrate their ability to safely operate, maintain, and
                troubleshoot conveyor pumps under real-world conditions.
              </li>
              <br />
              <li>
                Observation and Feedback: Trainers provide individual feedback
                throughout the course to help participants refine their skills
                and address any areas of improvement. These assessments are
                aligned with CPCS standards, ensuring participants meet the
                requirements for certification.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and assessments,
              participants will be awarded the CPCS A75 Conveyor Pump
              Certification. This qualification is highly respected in the
              construction industry and demonstrates the operator's proficiency
              in using conveyor pumps safely and effectively. Certification
              options include:
            </p>
            <br />
            <ul>
              <li>
                CPCS Red Trained Operator Card: This card is valid for two years
                and is issued to participants who have successfully completed
                the course and testing requirements. It provides an opportunity
                to gain practical experience on-site.
              </li>
              <br />
              <li>
                CPCS Blue Competent Operator Card: This card is valid for five
                years and is available to participants who complete an NVQ
                qualification alongside their training. It signifies advanced
                competency and enhances long-term career prospects.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Conveyor Pump A75 Training Course is an intensive program
              tailored for individuals seeking to advance their careers in
              construction. The course covers all aspects of conveyor pump
              operation, from basic mechanics to advanced troubleshooting
              techniques, ensuring participants are fully prepared to handle the
              demands of on-site operations.
            </p>
            <br />
            <p>
              To enroll in this course, participants must meet the following
              requirements:
            </p>
            <br />
            <ul>
              <li>
                Health and Safety Test: Participants must have passed the
                Operatives Health, Safety, and Environment Test within the last
                two years to ensure they are aware of construction site safety
                protocols.
              </li>
              <br />
              <li>
                Language Proficiency: A good command of spoken and written
                English is necessary to understand the course material and
                complete the assessments effectively. Whether you're new to
                conveyor pump operation or seeking certification to enhance your
                career, this course provides the training and qualifications you
                need to succeed.
              </li>
            </ul>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
