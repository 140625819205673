import "../css/footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>Contact Info</h3>
          <p>
            <a href="tel:+447475510591">Phone: +44 7475 510 591</a>
          </p>
          <p>
            <a href="mailto:info@alphacontraining.co.uk">
              Email: info@alphacontraining.co.uk
            </a>
          </p>

          <p>Address: 86 High Street, West Bromwich, B70 6JW</p>
        </div>
        <div className="footer-column">
          <h3>Follow Us</h3>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.tiktok.com/@alpha.con.training"
            target="_blank"
            rel="noopener noreferrer"
          >
            TikTok
          </a>
        </div>
      </div>
      <p>© 2024 Alpha Constructions. All rights reserved.</p>
    </footer>
  );
}
