import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSConcretePlacingBoom() {
  return (
    <>
      <Helmet>
        <title>
          A72 Static Concrete Placing Boom | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Static Concrete Placing Boom A72 Training Course to gain essential skills in operating and maintaining static concrete placing booms for construction projects."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A72 Static Concrete Placing Boom</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Static Concrete Placing Boom A72 Course is designed to
              provide participants with the expertise required to operate and
              maintain static concrete placing booms efficiently and safely.
              This course emphasizes a thorough understanding of operational
              techniques, safety protocols, and the practical skills necessary
              to handle construction tasks effectively. Whether you are starting
              a career in construction or seeking to enhance your current skill
              set, this course offers the knowledge and certification you need
              to excel.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of the mechanics and operational
                controls of static concrete placing booms.
              </li>
              <li>
                Application of essential safety protocols and effective response
                to emergency situations.
              </li>
              <li>
                Execution of routine maintenance, identifying faults, and
                implementing troubleshooting procedures.
              </li>
              <li>
                Hands-on training in equipment transport, setup, configuration,
                and safe operation.
              </li>
              <li>
                Proper techniques for arranging, anchoring, and securing
                delivery pipes and lines.
              </li>
              <li>
                Practical solutions for managing and resolving blockages during
                concrete placement.
              </li>
              <li>
                Cleaning down equipment post-operation and completing necessary
                documentation for maintenance and project records.
              </li>
              <li>
                Ensuring compliance with legal and operational standards while
                minimizing risks to personnel and equipment.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes detailed theoretical assessments to evaluate
              your understanding of operational principles, safety regulations,
              and best practices. Practical assessments focus on your ability to
              apply learned skills in real-world scenarios, including operating
              the placing boom, resolving blockages, and maintaining equipment.
              Successful completion ensures that you are proficient in handling
              both routine and challenging tasks on construction sites.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who successfully complete the course and pass all
              assessments will be awarded the CPCS Red Trained Operator Card for
              Static Concrete Placing Boom (A72). This certification is
              recognized across the construction industry and demonstrates your
              competency in operating and maintaining static concrete placing
              booms.
            </p>
            <br />
            <p>
              The Red Card is valid for two years, during which time
              participants are encouraged to gain practical experience and
              complete the relevant NVQ to progress to the CPCS Blue Competent
              Operator Card. The Blue Card represents long-term validation of
              your skills and knowledge, boosting your career prospects.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Static Concrete Placing Boom A72 Course is your gateway
              to becoming a competent and confident operator. By combining
              theoretical knowledge with practical training, this course equips
              you with the skills needed to excel in concrete placement
              operations. With an emphasis on safety, efficiency, and industry
              standards, you will be prepared to tackle the challenges of modern
              construction projects.
            </p>
            <br />
            <p>
              Whether you're entering the construction field or seeking to
              expand your expertise, this course provides the tools and
              certification required to advance your career. Enroll today to
              gain the skills and recognition that will set you apart in the
              construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
