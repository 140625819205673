import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/npors.css";
import { Helmet } from "react-helmet";
import agriculturalTractor from "../images/n601-agriculturaltractor.jpg";
import cableAvoidanceTools from "../images/n304-cableavoidancetools.jpeg";
import chainSaw from "../images/n602-chainsaw.jpg";
import crusher from "../images/n207-crusher.jpg";
import dozer from "../images/n215-dozer.png";
import excavator180 from "../images/n201-excavator180.jpg";
import excavator360 from "../images/n202-excavator360.jpg";
import excavatorMicro from "../images/n016-excavatormicro.jpg";
import excavatorAsACrane from "../images/n100-excavatorasacrane.jpg";
import forwardTippingDumper from "../images/n204-forwardtippingdumper.webp";
import forwardTippingMiniDumper from "../images/n139-forwardtippingmindumper.jpg";
import gritterAndSnowplough from "../images/n802-gritterandsnowplough.jpg";
import harnessAndFallArrest from "../images/n723-harnessandfallarrest.jpg";
import industrialCounterBalancedForklift from "../images/n001-industrialcounterbalancelifttruck.jpg";
import loadingShovel from "../images/n209-loadingshovel.jpg";
import logHandler from "../images/n106-loghandler.jpg";
import lorryLoader from "../images/n107-lorryloader.jpg";
import mewpBoom from "../images/n108-mewpboom.jpg";
import mewpScissor from "../images/n109-mewpscissor.jpg";
import marineKnuckleBoomCrane from "../images/n014-marineknuckleboomcrane.jpg";
import paver from "../images/n220-paver.jpg";
import plantLoaderSecurer from "../images/n120-plantloadersecurer.jpg";
import plantMachineryMarhsall from "../images/n133-plantmachinerymarshall.jpg";
import plantMover from "../images/n132-plantmover.webp";
import quickHitchAwareness from "../images/n726-quickhitchawareness.jpg";
import rearTippingDumper from "../images/n205-reartippingdumper.webp";
import roadPlaner from "../images/n216-roadplaner.jpg";
import roadRoller from "../images/n214-roadroller.jpg";
import roadSweeper from "../images/n217-roadsweeper.jpg";
import safeWorkingAtNight from "../images/n404-safeworkingatnight.png";
import safetyAwareness from "../images/s001-safetyawareness.jpg";
import screener from "../images/n208-screener.webp";
import slingerSignaller from "../images/n402-slingersignaller.jpg";
import telescopicHandler from "../images/n010-telescopichandler.png";
import vehicleMarshall from "../images/n403-vehiclemarshall.jpg";

export default function Npors() {
  return (
    <>
      <Helmet>
        <title>NPORS | Alpha Construction Training</title>
        <meta
          name="description"
          content="Learn about NPORS certification for plant operators in the UK at Alpha Construction Training. Our NPORS training and assessments cover various machinery categories, including cranes, earthmoving, material handling, and demolition equipment. Find out the requirements and assessment details."
        />
      </Helmet>
      <Navbar />
      <div className="npors-container">
        <div className="npors-hero">
          <h1>NPORS Training & Assessments</h1>
        </div>

        <div className="npors-content">
          <div className="npors-info">
            <div className="npors-description">
              <h2>What is NPORS?</h2>
              <p>
                Since 1992, the National Plant Operators Registration Scheme
                (NPORS) has been a trusted certification program ensuring
                machine operators are skilled and compliant with safety
                standards in the UK and globally. NPORS is recognised by the
                Health and Safety Executive (HSE) and offers two types of
                cards—red and blue—to verify operator training and assessment
                levels.
              </p>
              <br />
              <p>
                NPORS certification is displayed with categories of expertise,
                giving employers confidence in the qualifications and compliance
                of their workforce. This program offers a flexible,
                cost-effective alternative to CPCS while maintaining
                industry-leading standards.
              </p>
            </div>
          </div>

          <div className="npors-requirements">
            <div className="requirements-content">
              <h2>Introduction</h2>
              <p>
                NPORS offers three primary card options to cater to different
                needs and levels of experience:
              </p>
              <p>
                The Traditional NPORS Route, which does not include a CSCS logo,
                is ideal for operators who don’t require CSCS validation. This
                route includes training followed by theory and practical
                assessments, resulting in a 5-year NPORS Traditional Card upon
                successful completion.
              </p>
              <br />
              <p>
                The NPORS CSCS Red Card is designed for operators needing a
                CSCS-endorsed card. This card involves similar training and
                assessments and is valid for 2 years. During this period,
                operators can work towards upgrading to a Blue Card.
              </p>
              <br />
              <p>
                To qualify for the NPORS CSCS Blue Card, operators must complete
                an NVQ on-site. This involves creating a portfolio of evidence,
                such as photos, videos, and discussions, to demonstrate their
                competency. The Blue Card is valid for 5 years.
              </p>
              <br />
              <p>
                NPORS stands out from CPCS by offering greater flexibility in
                training delivery and assessment locations, often at a lower
                cost. Unlike CPCS, NPORS permits on-site training, minimizing
                workplace disruptions. Additionally, NPORS cards can include a
                CSCS logo, aligning them with the UK construction industry
                standards.
              </p>
            </div>

            <div className="assessment-info">
              <h2>About the NPORS Assessment</h2>
              <h3 className="assessment-heading">NPORS Theory Test</h3>
              <p>
                The theory test is an essential part of plant operator training
                or testing, designed to assess your understanding of the safety
                regulations, machinery operation, and other important aspects
                required to operate heavy equipment competently. The theory test
                ensures that, as an operator, you not only know how to handle
                the machine but also understand the risks, procedures, and
                guidelines that must be followed to maintain a safe working
                environment.
                <br />
                <br />
                <li>The test typically covers topics such as:</li>
                <li>Health and safety regulations</li>
                <li>Operator responsibilities</li>
                <li>Risk assessments</li>
                <li>Machine-specific functions</li>
                <li>Legal compliance and certification</li>
              </p>
              <h3 className="assessment-heading">NPORS Practical Test</h3>
              <p>
                The NPORS Practical Test is a hands-on evaluation designed to
                assess an operator’s ability to safely and efficiently handle
                machinery. The test includes a series of exercises that simulate
                real-world scenarios, focusing on practical skills, safety
                awareness, and compliance with industry standards.
              </p>
              <br />
              <p>Key elements of the practical test include:</p>
              <ul>
                <li>
                  Candidates must demonstrate how to perform pre-use
                  inspections, identifying potential issues such as fluid
                  levels, tire conditions, or mechanical faults.
                </li>
                <br />
                <li>
                  This involves preparing the machinery for specific tasks, such
                  as fitting attachments or ensuring the work area is safe and
                  compliant with regulations.
                </li>
                <br />
                <li>
                  Candidates are evaluated on their ability to operate the
                  machinery effectively. Tasks may include digging, lifting,
                  loading, or maneuvering, depending on the equipment being
                  tested.
                </li>
                <br />
                <li>
                  The test assesses the operator's ability to navigate the
                  machine across various terrains, focusing on stability,
                  precision, and awareness of surroundings.
                </li>
                <br />
                <li>
                  Operators are required to demonstrate safe working practices,
                  such as maintaining proper communication signals, observing
                  site safety protocols, and following emergency procedures.
                </li>
                <br />
                <li>
                  Candidates must show they can safely shut down and secure the
                  machinery, including parking it in a safe position, switching
                  off the engine, and performing post-operation checks.
                </li>
              </ul>
              <p>
                The duration of the practical test depends on the type of
                machinery and the complexity of the tasks. During the test,
                candidates are closely monitored by an assessor to ensure
                compliance with safety regulations and industry standards.
                Successful completion of the test demonstrates the candidate's
                competence and readiness to operate machinery in a professional
                environment.
              </p>

              <h3 className="assessment-heading">NPORS Renewals</h3>

              <p>
                Renewing your NPORS card ensures your skills remain current and
                compliant with industry requirements. To renew, you must submit
                a renewal form signed by your employer, verifying your
                competence. Completing a refresher course is also recommended to
                update your knowledge and maintain independent accreditation.
              </p>
            </div>
          </div>

          <div className="npors-categories">
            <div className="categories-list">
              <div className="category">
                <h2>NPORS Categories</h2>
                <div className="button-list">
                  <button
                    onClick={() =>
                      (window.location.href = "/agriculturaltractor")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N601 Agricultural Tractor</div>
                      <div class="button-image">
                        <img
                          src={agriculturalTractor}
                          alt="Agricultural Tractor"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cableavoidance")}
                  >
                    <div class="button-content">
                      <div class="button-text">N304 Cable Avoidance Tools</div>
                      <div class="button-image">
                        <img
                          src={cableAvoidanceTools}
                          alt="Cable Avoidance Tools"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/chainsawoperation")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N602 Chain Saw</div>
                      <div class="button-image">
                        <img src={chainSaw} alt="Chain Saw" />
                      </div>
                    </div>
                  </button>
                  <button onClick={() => (window.location.href = "/crusher")}>
                    <div class="button-content">
                      <div class="button-text">N207 Crusher</div>
                      <div class="button-image">
                        <img src={crusher} alt="Crusher" />
                      </div>
                    </div>
                  </button>
                  <button onClick={() => (window.location.href = "/dozer")}>
                    <div class="button-content">
                      <div class="button-text">N215 Dozer</div>
                      <div class="button-image">
                        <img src={dozer} alt="Dozer" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/excavator180")}
                  >
                    <div class="button-content">
                      <div class="button-text">N201 Excavator 180°</div>
                      <div class="button-image">
                        <img src={excavator180} alt="Excavator180" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/excavator360")}
                  >
                    <div class="button-content">
                      <div class="button-text">N202 Excavator 360°</div>
                      <div class="button-image">
                        <img src={excavator360} alt="Excavator360" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/excavatorcrane")}
                  >
                    <div class="button-content">
                      <div class="button-text">N100 Excavator as a Crane</div>
                      <div class="button-image">
                        <img
                          src={excavatorAsACrane}
                          alt="Excavator as a Crane"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/excavatormicro")}
                  >
                    <div class="button-content">
                      <div class="button-text">N016 Excavator Micro</div>
                      <div class="button-image">
                        <img src={excavatorMicro} alt="Micro Excavator" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/forwardtippingdumper")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N204 Forward Tipping Dumper</div>
                      <div class="button-image">
                        <img
                          src={forwardTippingDumper}
                          alt="Forward Tipping Dumper"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/forwardtippingminidumper")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        N139 Forward Tipping Mini Dumper
                      </div>
                      <div class="button-image">
                        <img
                          src={forwardTippingMiniDumper}
                          alt="Forward Tipping Mini Dumper"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/grittersnowplough")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N802 Gritter & Snowplough</div>
                      <div class="button-image">
                        <img
                          src={gritterAndSnowplough}
                          alt="Gritter and Snowplough"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/harnessandfallarrest")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        N723 Harness and Fall Arrest
                      </div>
                      <div class="button-image">
                        <img
                          src={harnessAndFallArrest}
                          alt="Harness and Fall Arrest"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href =
                        "/industrialcounterbalancedforklift")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        N001 Industrial Counterbalance Lift Truck
                      </div>
                      <div class="button-image">
                        <img
                          src={industrialCounterBalancedForklift}
                          alt="Industrial Counter Balanced Forklift"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/loadingshovel")}
                  >
                    <div class="button-content">
                      <div class="button-text">N209 Loading Shovel</div>
                      <div class="button-image">
                        <img src={loadingShovel} alt="Loading Shovel" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/loghandler")}
                  >
                    <div class="button-content">
                      <div class="button-text">N106 Log Handler</div>
                      <div class="button-image">
                        <img src={logHandler} alt="Log Handler" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/lorryloader")}
                  >
                    <div class="button-content">
                      <div class="button-text">N107 Lorry Loader</div>
                      <div class="button-image">
                        <img src={lorryLoader} alt="Lorry Loader" />
                      </div>
                    </div>
                  </button>
                  <button onClick={() => (window.location.href = "/mewpboom")}>
                    <div class="button-content">
                      <div class="button-text">N108 M.E.W.P. Boom</div>
                      <div class="button-image">
                        <img src={mewpBoom} alt="M.E.W.P Boom" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/mewpscissor")}
                  >
                    <div class="button-content">
                      <div class="button-text">N109 M.E.W.P. Scissor Lift</div>
                      <div class="button-image">
                        <img src={mewpScissor} alt="M.E.W.P Scissor" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/marineknuckleboom")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        N014 Marine Knuckle Boom Crane
                      </div>
                      <div class="button-image">
                        <img
                          src={marineKnuckleBoomCrane}
                          alt="Marine Knuckle Boom Crane"
                        />
                      </div>
                    </div>
                  </button>
                  <button onClick={() => (window.location.href = "/paver")}>
                    <div class="button-content">
                      <div class="button-text">N220 Paver</div>
                      <div class="button-image">
                        <img src={paver} alt="Paver" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/plantloadersecurer")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N120 Plant Loader & Securer</div>
                      <div class="button-image">
                        <img
                          src={plantLoaderSecurer}
                          alt="Plant Loader & Securer"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/plantmachinery")}
                  >
                    <div class="button-content">
                      <div class="button-text">
                        N133 Plant Machinery Marshall
                      </div>
                      <div class="button-image">
                        <img
                          src={plantMachineryMarhsall}
                          alt="=Plant Machinery Marshall"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/plantmover")}
                  >
                    <div class="button-content">
                      <div class="button-text">N132 Plant Mover</div>
                      <div class="button-image">
                        <img src={plantMover} alt="Plant Mover" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/quickhitchawareness")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N726 Quick Hitch Awareness</div>
                      <div class="button-image">
                        <img
                          src={quickHitchAwareness}
                          alt="Quick Hitch Awareness"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/reartippingdumper")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N205 Rear Tipping Dumper</div>
                      <div class="button-image">
                        <img
                          src={rearTippingDumper}
                          alt="Rear Tipping Dumper"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/roadplaner")}
                  >
                    <div class="button-content">
                      <div class="button-text">N216 Road Planer</div>
                      <div class="button-image">
                        <img src={roadPlaner} alt="Road Planer" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/rideonroller")}
                  >
                    <div class="button-content">
                      <div class="button-text">N214 Road Roller</div>
                      <div class="button-image">
                        <img src={roadRoller} alt="Road Roller" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/roadsweeper")}
                  >
                    <div class="button-content">
                      <div class="button-text">N217 Road Sweeper</div>
                      <div class="button-image">
                        <img src={roadSweeper} alt="Road Sweeper" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/safeworkingatnight")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N404 Safe Working At Height</div>
                      <div class="button-image">
                        <img
                          src={safeWorkingAtNight}
                          alt="Safe Working At Height"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/safetyawareness")}
                  >
                    <div class="button-content">
                      <div class="button-text">S001 Safety Awareness</div>
                      <div class="button-image">
                        <img src={safetyAwareness} alt="Safety Awareness" />
                      </div>
                    </div>
                  </button>
                  <button onClick={() => (window.location.href = "/screener")}>
                    <div class="button-content">
                      <div class="button-text">N208 Screener</div>
                      <div class="button-image">
                        <img src={screener} alt="Screener" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/slingersignaller")}
                  >
                    <div class="button-content">
                      <div class="button-text">N402 Slinger/Signaller</div>
                      <div class="button-image">
                        <img src={slingerSignaller} alt="Slinger/Signaller" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/telescopichandler")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">N010 Telescopic Handler</div>
                      <div class="button-image">
                        <img src={telescopicHandler} alt="Telescopic Handler" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/vehiclemarshall")}
                  >
                    <div class="button-content">
                      <div class="button-text">N403 Vehicle Marshal</div>
                      <div class="button-image">
                        <img src={vehicleMarshall} alt="Vehicle Marshal" />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
