import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCraneSupervisor() {
  return (
    <>
      <Helmet>
        <title>A62 Crane Supervisor | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in our CPCS Crane Supervisor (A62) Training Course to gain comprehensive knowledge and certification in crane supervision, lifting operations, and safety."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A62 Crane Supervisor</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course aims to equip candidates with the knowledge and skills
              required to effectively supervise lifting operations in compliance
              with health and safety regulations and industry best practices.
              The training emphasizes the critical role of a Crane Supervisor in
              maintaining safe and efficient lifting operations while ensuring
              compliance with legal and operational guidelines.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Legislation, regulations, and Codes of Practice, including the
                Health and Safety at Work Act and LOLER 1998.
              </li>
              <li>
                How to develop and implement lifting plans and method
                statements.
              </li>
              <li>
                Understanding the responsibilities of a Crane Supervisor,
                including communication and teamwork with lifting teams.
              </li>
              <li>
                Types of lifting equipment, crane terminology, and safe use
                practices.
              </li>
              <li>
                Reading and interpreting crane duty charts and other
                documentation.
              </li>
              <li>
                Identifying and mitigating risks during lifting operations.
              </li>
              <li>
                Techniques for managing exclusion zones and preparing work
                areas.
              </li>
              <li>
                British Standard 7121 guidelines and their practical application
                in lifting operations.
              </li>
              <li>
                Ensuring stability, proper rigging, and load calculations.
              </li>
              <li>
                Effective communication during lifting operations, including the
                use of hand signals and radios.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The assessment involves a combination of theoretical and practical
              evaluations to ensure candidates have a comprehensive
              understanding of crane supervision responsibilities. This
              includes:
            </p>
            <br />
            <ul>
              <li>
                Completing a written test on health and safety regulations,
                crane terminology, and supervision responsibilities.
              </li>
              <li>
                Demonstrating practical skills in supervising a lifting
                operation scenario.
              </li>
              <li>
                Observing and critiquing lifting plans and their execution.
              </li>
            </ul>
            <br />
            <p>
              Candidates must demonstrate competence in these areas to achieve
              certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and assessment,
              candidates will be awarded a Red CPCS Trained Operator Card. This
              card remains valid for two years and certifies the holder's
              ability to supervise lifting operations safely and effectively.
            </p>
            <br />
            <p>
              To progress to the Blue CPCS Competence Card, candidates must
              complete an NVQ Level 4 in Controlling Lifting Operations -
              Supervising Lifts within two years. The Blue Card can be renewed
              every five years upon providing evidence of ongoing competence and
              experience in the role.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A62 Crane Supervisor course is designed for individuals with
              prior experience in crane operations or lifting activities. It
              prepares candidates to effectively supervise lifting operations by
              providing comprehensive knowledge of safety protocols, operational
              guidelines, and legal requirements. Participants must have passed
              the Supervisors Health, Safety, and Environment Test within the
              last two years and possess a good command of spoken and written
              English to fully engage in the training and assessment.
              Familiarity with British Standard 7121 Safe Use of Cranes or
              similar industry standards is essential. Additionally, candidates
              are required to bring appropriate personal protective equipment
              (PPE), including safety boots and high-visibility clothing, for
              practical sessions.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
