import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTrailerMountedConcretePump() {
  return (
    <>
      <Helmet>
        <title>
          A44 Trailer Mounted Concrete Pump | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Trailer Mounted Concrete Pump A44 training course at Alpha Construction Training to gain essential skills for operating trailer-mounted concrete pumps safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A44 Trailer Mounted Concrete Pump</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Trailer Mounted Concrete Pump A44 CPCS training course is
              designed to provide participants with the knowledge and practical
              skills required to operate trailer-mounted concrete pumps safely
              and efficiently. This course ensures compliance with industry
              standards and prepares participants for real-world applications.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the nature of the industry sector and the role and
                responsibilities of a plant operator.
              </li>
              <li>
                Learn the purpose of principal components, basic construction,
                controls, and terminology of trailer-mounted concrete pumps.
              </li>
              <li>
                Conform to manufacturer’s requirements as outlined in the
                operator’s handbook and relevant regulations.
              </li>
              <li>
                Perform pre-use checks to ensure machine safety and
                functionality.
              </li>
              <li>
                Transport, set up, configure, and maneuver the trailer-mounted
                concrete pump efficiently.
              </li>
              <li>
                Arrange, anchor, and secure all pipes and lines for safe
                operation.
              </li>
              <li>
                Ensure the concrete mix is prepared and ready for pumping.
              </li>
              <li>
                Resolve blockages and handle foreign object removal safely.
              </li>
              <li>
                Follow the clean-down procedure and complete relevant
                documentation.
              </li>
              <li>
                Perform shutdown and securing procedures for the equipment.
              </li>
              <li>
                Explain loading, unloading, and transport preparation for the
                pump.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines classroom-based instruction with practical
              exercises. Participants will be assessed through CPCS technical
              tests to evaluate their knowledge and operational competence.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Candidates who pass the CPCS technical tests will receive a Red
              CPCS Trained Operator Card, valid for two years. To upgrade to a
              Blue CPCS Competence Card, participants must complete an NVQ Level
              2 in Plant Operations within the two-year period. The Blue Card is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Trailer Mounted Concrete Pump A44 course equips
              participants with essential skills for operating trailer-mounted
              concrete pumps, focusing on safety, maintenance, and efficiency.
              It is suitable for both new and experienced operators seeking
              recognized certification and enhanced career opportunities.
            </p>
            <p>
              Completing this course enables participants to meet industry
              standards, improve employability, and achieve professional growth
              in the construction sector.
            </p>
            <p>
              Enroll today and take the first step toward becoming a certified
              trailer-mounted concrete pump operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
