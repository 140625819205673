import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSoilStabiliser() {
  return (
    <>
      <Helmet>
        <title>A71 Soil Stabiliser | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Soil Stabiliser (A71) Training Course to master soil stabilisation techniques, machinery operation, and safety protocols."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A71 Soil Stabiliser</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A71 Soil Stabiliser course is designed to provide participants
              with an in-depth understanding of soil stabilisation techniques
              and the use of specialised machinery. The primary objective is to
              ensure that candidates can operate soil stabilisers safely and
              efficiently, comply with health and safety standards, and enhance
              the quality of their work on construction sites.
            </p>
            <br />
            <p>
              This course aims to instill the ability to identify and address
              potential challenges in soil stabilisation, including uneven
              terrain, varying soil conditions, and environmental factors. By
              the end of the program, participants will be fully equipped to
              manage soil stabilisation tasks from planning to execution.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the principles and scientific applications of soil
                stabilisation, including its benefits and limitations.
              </li>
              <li>
                Comprehensive knowledge of different soil types, their
                properties, and how they affect stabilisation processes.
              </li>
              <li>
                Operating and maintaining soil stabilisers, including routine
                inspections, pre-start checks, and understanding equipment
                manuals.
              </li>
              <li>
                Adhering to industry-specific safety regulations and
                environmental guidelines during stabilisation projects.
              </li>
              <li>
                Techniques for managing soil stabilisation in adverse
                conditions, such as wet or uneven terrain.
              </li>
              <li>
                Troubleshooting machinery to address operational issues and
                maintain consistent performance.
              </li>
              <li>
                Best practices for maneuvering and positioning soil stabilisers
                on construction sites to ensure precision and safety.
              </li>
              <li>
                Efficiently coordinating with team members and understanding the
                role of stabilisation in broader construction projects.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course employs a combination of theoretical instruction and
              hands-on practical exercises to prepare participants for
              real-world scenarios. Assessments are conducted to evaluate both
              technical knowledge and operational competency.
            </p>
            <br />
            <ul>
              <li>
                A written or multiple-choice theory test to assess understanding
                of soil stabilisation principles, equipment, and safety
                protocols.
              </li>
              <br />
              <li>
                A practical skills test where candidates demonstrate their
                ability to operate soil stabilisers effectively, including
                pre-start checks, machine operation, and site-specific tasks.
              </li>
              <br />
              <li>
                Continuous observation and feedback during practical exercises
                to identify areas for improvement. Participants are required to
                meet specific performance criteria in both assessments to
                successfully complete the course.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the course, participants will receive
              the CPCS A71 Soil Stabiliser certification. This highly regarded
              qualification validates the skills and knowledge required to
              operate soil stabilisers effectively and in compliance with
              industry standards.
            </p>
            <br />
            <p>
              Certification includes eligibility for the Red Trained Operator
              Card, which is valid for two years. During this period, operators
              can further their qualifications by completing an NVQ to obtain
              the Blue Competent Operator Card, valid for five years.
            </p>
            <br />
            <p>
              The CPCS certification enhances employability by demonstrating
              proficiency in specialised machinery operation, making candidates
              valuable assets in construction projects requiring soil
              stabilisation expertise.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A71 Soil Stabiliser course is ideal for construction
              professionals looking to expand their skillset in soil
              stabilisation and machinery operation. It covers a wide range of
              topics, from basic principles to advanced techniques, ensuring
              participants are well-prepared to meet industry demands.
            </p>
            <br />
            <p>
              Entry requirements include a valid Operatives Health, Safety, and
              Environment Test certificate obtained within the last two years.
              Proficiency in spoken and written English is necessary to fully
              engage with the course material and assessments.
            </p>
            <br />
            <p>
              The course provides a blend of theoretical knowledge and practical
              application, enabling participants to gain confidence in their
              abilities and contribute effectively to construction projects. By
              completing this program, individuals will be equipped to handle
              complex soil stabilisation tasks and advance their careers in the
              construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
