import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDemolitionPlant() {
  return (
    <>
      <Helmet>
        <title>D90 Demolition Plant | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Demolition Plant D90 Training Course to gain essential skills in operating demolition plant machinery safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>D90 Demolition Plant</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Demolition Plant D90 Course is tailored to equip
              individuals with the theoretical knowledge and practical skills
              necessary to operate demolition plant machinery effectively. This
              course is essential for those looking to build a solid foundation
              in the demolition sector, focusing on safety protocols, equipment
              maintenance, and operational techniques to meet industry
              standards. Participants will gain the confidence and expertise
              required to perform demolition tasks safely and efficiently in
              challenging environments.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of safety regulations, including
                risk assessment and hazard management in demolition operations.
              </li>
              <li>
                Mastery of operational techniques and equipment handling
                specific to demolition plant machinery, ensuring maximum
                efficiency.
              </li>
              <li>
                In-depth knowledge of machine maintenance, routine checks, and
                troubleshooting for ensuring equipment reliability and
                minimizing downtime.
              </li>
              <li>
                Hands-on training to develop competence in dismantling
                structures and handling debris safely and efficiently.
              </li>
              <li>
                Familiarity with tools and equipment used in demolition, such as
                hand tools, pneumatic equipment, and cutting tools.
              </li>
              <li>
                Advanced safety measures, including working at heights, using
                abrasive wheels, and operating in confined spaces.
              </li>
              <li>
                Techniques for managing hazards associated with underground
                utilities, overhead services, and other site-specific risks.
              </li>
              <li>
                Best practices for maintaining safe and organized work areas to
                prevent accidents and enhance operational flow.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course integrates rigorous theoretical and practical
              assessments designed to validate your understanding and
              proficiency in demolition plant operations. The theoretical
              assessment focuses on safety protocols, operational principles,
              and industry regulations. The practical segment requires
              participants to demonstrate their ability to safely operate
              machinery, perform equipment maintenance, and apply best practices
              in real-world scenarios.
            </p>
            <p>
              Successful completion of the assessments ensures you are
              well-prepared to handle the complexities of demolition work,
              giving you a competitive edge in the construction industry.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              After successfully completing the course and passing the required
              assessments, you will be awarded the CPCS Red Trained Operator
              Card for Demolition Plant (D90). This certification is widely
              recognized within the construction and demolition industries,
              signifying your expertise in demolition machinery operation.
            </p>
            <p>
              The CPCS Red Card is valid for two years. During this period, you
              are encouraged to gain on-site experience and complete the
              relevant NVQ to progress to the CPCS Blue Competent Operator Card,
              which offers long-term recognition of your skills and knowledge.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Demolition Plant D90 Course provides a comprehensive
              blend of theoretical and practical training to prepare you for the
              demands of the demolition sector. With a strong emphasis on
              safety, operational excellence, and equipment maintenance, this
              course ensures you are well-equipped to handle the challenges of
              modern demolition work.
            </p>
            <p>
              Whether you are new to the industry or seeking to enhance your
              existing skills, this course offers the knowledge and hands-on
              experience needed to excel. By the end of the course, you will be
              fully prepared to take the CPCS test and advance your career in
              the construction and demolition industries.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
