import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator360Below10T() {
  return (
    <>
      <Helmet>
        <title>
          A58 Excavator 360º Below 10 Tonne | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Excavator 360 Below 10 Tonne Training course to gain essential skills and certification for operating mini excavators."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A58 Excavator 360º Below 10 Tonne</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is designed to provide participants with
              the skills and knowledge required to operate mini excavators
              safely and productively. It caters to individuals with varying
              levels of experience, offering tailored training durations based
              on skill levels. Participants will gain practical expertise,
              operational confidence, and a deep understanding of excavator
              mechanics and safety.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of excavator controls and functions
                for optimal operation.
              </li>
              <li>
                Effective techniques for trenching, leveling, and earthmoving
                tasks.
              </li>
              <li>
                Procedures for safely lifting and handling loads using
                excavators.
              </li>
              <li>
                Proper fitting, use, and maintenance of ancillary equipment such
                as buckets and hydraulic tools.
              </li>
              <li>
                Key safety measures to prevent accidents and maintain compliance
                with workplace safety regulations.
              </li>
              <li>
                Interpreting technical documentation, such as site plans and
                excavation specifications.
              </li>
              <li>
                Advanced skills like working to precise levels using laser
                equipment and interpreting level pegs and profiles.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment comprises a mix of theoretical and practical
              evaluations. Classroom sessions include interactive lessons,
              safety briefings, and discussion-based learning, while the
              practical component tests operator skills in real-world scenarios.
              The course concludes with the CPCS technical test, covering safety
              protocols, operational techniques, and equipment handling
              expertise.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS technical tests,
              participants will receive the CPCS Red Trained Operator Card. This
              certification demonstrates proficiency in operating 360º
              excavators below 10 tonnes and is valid for two years. During this
              period, participants must achieve an NVQ Level 2 in Plant
              Operations to upgrade to the CPCS Blue Competence Card, which
              offers long-term recognition as a competent operator.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Excavator 360 Below 10 Tonne Training Course at Alpha
              Construction Training provides a comprehensive pathway to becoming
              a certified operator. Designed to suit both beginners and those
              with prior experience, this program equips participants with
              practical skills, theoretical knowledge, and industry-recognized
              credentials. Whether you're entering the construction field or
              seeking to enhance your expertise, this course ensures you're
              prepared for safe and efficient operation on site.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
