import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function VehicleMarshal() {
  return (
    <>
      <Helmet>
        <title>N403 Vehicle Marshal | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N403 Vehicle Marshal Training Course, including course objectives, learning outcomes, assessment, and certification options."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N403 Vehicle Marshal</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals involved in the movement
              of vehicles, providing health and safety knowledge alongside
              practical guidance on safely directing vehicle operations. It aims
              to reduce risks associated with vehicle movement and improve
              overall site safety.
            </p>
            <br />
            <p>
              The course ensures participants can contribute to safer working
              environments by understanding the principles of traffic management
              and the role of a vehicle marshal in minimizing accidents and
              improving communication on-site.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the industry, relevant legislation, associated
                hazards, and the responsibilities of a vehicle marshal,
                including legal compliance and safety standards.
              </li>
              <li>
                Gain familiarity with the manufacturer’s handbook for the
                vehicle/machine being guided, understanding its key components,
                specifications, and limitations.
              </li>
              <li>
                Learn and use approved hand signals, signs, and alternative
                forms of communication, including the use of radios and visual
                aids.
              </li>
              <li>
                Identify and maintain appropriate PPE for vehicle marshal
                operations, ensuring it meets the required standards for
                different scenarios.
              </li>
              <li>
                Conduct essential safety checks, including stop blocks, tipping
                areas, and traffic routes, ensuring they are free from hazards.
              </li>
              <li>
                Establish and manage exclusion zones during unloading
                operations, ensuring safe distances are maintained at all times.
              </li>
              <li>
                Safely and effectively guide vehicles in forward and reverse
                directions, including in confined spaces and blind areas, using
                situational awareness and proper techniques.
              </li>
              <li>
                Understand environmental considerations when performing duties,
                such as minimizing noise pollution, dust, and emissions.
              </li>
              <li>
                Perform end-of-shift shut down and parking procedures, ensuring
                vehicles are safely secured and hazards are eliminated.
              </li>
              <li>
                Respond effectively to on-site emergencies involving vehicles,
                applying the appropriate communication and safety protocols.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>To achieve certification, candidates must complete:</p>
            <br />
            <ul>
              <li>
                NPORS Theory assessment comprising open questions and
                multiple-choice, covering all aspects of the course content,
                including legislation, safety checks, and communication
                techniques.
              </li>
              <br />
              <li>
                NPORS Practical assessment, where candidates demonstrate their
                ability to perform tasks such as guiding vehicles, managing
                exclusion zones, and conducting pre-shift safety checks.
              </li>
            </ul>
            <p>
              The practical assessment simulates real-world scenarios to ensure
              participants can apply their knowledge effectively in a worksite
              environment.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive an NPORS card based on the
              following criteria:
            </p>
            <br />
            <ul>
              <li>
                <strong>Existing NPORS Card Holder – No NVQs Held:</strong>
                <br />A Trained Operator card valid for two years. During this
                period, operators must achieve the requisite NVQs with unit
                credits applicable to their categories.
              </li>
              <br />
              <li>
                <strong>Existing NPORS Card Holder – NVQs Held:</strong>
                <br />A Competent Operator card valid for five years, along with
                a CPD record/operator logbook documenting their professional
                development and workplace hours.
              </li>
              <li>
                Operators can opt for certification tailored to site
                requirements, such as adding specific endorsements for machinery
                or roles.
              </li>
            </ul>
            <p>
              NPORS certification is recognized nationwide and ensures operators
              meet the competency standards required by employers and regulatory
              authorities.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The Vehicle Marshal course equips participants with essential
              skills and knowledge to safely and effectively manage the movement
              of vehicles on site. Participants gain an in-depth understanding
              of their responsibilities, including ensuring that vehicles are
              directed safely in compliance with industry standards and best
              practices.
            </p>
            <br />
            <p>
              This training enhances workplace safety, improves operational
              efficiency, and reduces risks associated with vehicle movement.
              Whether working in construction, logistics, or industrial sites,
              participants will leave the course confident in their ability to
              perform their duties as a vehicle marshal.
            </p>
            <p>
              By completing this course, individuals contribute to creating a
              safer working environment for all site personnel and visitors.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
