import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigDrivenAbove20Tonnes() {
  return (
    <>
      <Helmet>
        <title>
          A46 Piling Rig Driven Above 20 Tonnes | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in the CPCS Piling Rig Driven Above 20 Tonnes A46 training course at Alpha Construction Training to gain comprehensive knowledge and practical skills required for operating piling rigs efficiently, ensuring safety and compliance with industry standards."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A46 Piling Rig Driven Above 20 Tonnes</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A46 CPCS Piling Rig Driven Above 20 Tonnes training course is
              tailored to provide participants with a comprehensive
              understanding of piling rig operations. This course emphasizes
              practical skill development, safety practices, and compliance with
              industry standards. By the end of the course, participants will be
              equipped to operate piling rigs effectively in various site
              conditions.
            </p>
            <br />
            <p>
              The course also aims to instill confidence in candidates, enabling
              them to handle real-world challenges associated with operating
              heavy machinery.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an in-depth understanding of the construction industry and
                the critical role of piling rig operators.
              </li>
              <li>
                Study the purpose, design, and functionality of principal
                components, controls, and key terminology of piling rigs.
              </li>
              <li>
                Master pre-start and post-operation checks to ensure equipment
                safety and compliance.
              </li>
              <li>
                Learn how to prepare piling rigs for transportation, assembly,
                and operational readiness.
              </li>
              <li>
                Acquire techniques for traveling with a piling rig over various
                terrains, including rough and uneven surfaces.
              </li>
              <li>
                Develop expertise in maneuvering piling rigs in restricted and
                congested spaces.
              </li>
              <li>
                Understand and execute procedures for positioning, configuring,
                and operating piling rigs for driven works.
              </li>
              <li>
                Learn to identify and mitigate risks related to underground and
                overhead services.
              </li>
              <li>
                Safely perform piling operations, including installing
                displacement piles to completion.
              </li>
              <li>
                Understand communication protocols and signal compliance during
                operations.
              </li>
              <li>
                Maintain optimal working conditions, adhering to legal and
                environmental safety standards.
              </li>
              <li>
                Comprehend the lifting requirements and limitations when using
                piling rigs.
              </li>
              <li>
                Execute proper shutdown, de-rigging, and securing procedures
                post-operation.
              </li>
              <li>
                Understand best practices for loading and unloading piling rigs
                for transportation to and from sites.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              Participants will undergo both theoretical and practical
              assessments to gauge their understanding and application of the
              skills taught during the course. Assessments include:
            </p>
            <br />
            <ul>
              <li>
                CPCS technical theory test to evaluate knowledge of the piling
                rig.
              </li>
              <li>
                Practical operational tests, ensuring competence in machine
                handling, safety adherence, and task execution.
              </li>
              <li>
                Continuous instructor evaluations during hands-on training
                sessions.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and passing the CPCS
              technical tests, candidates will be awarded:
            </p>
            <br />
            <ul>
              <li>
                A Red CPCS Trained Operator Card, valid for two years,
                certifying initial competence.
              </li>
              <li>
                Eligibility to upgrade to a Blue CPCS Competence Card upon
                completion of an NVQ Level 2 in Plant Operations within the
                validity period of the Red Card.
              </li>
              <li>
                Blue Card holders can renew their certification every five
                years, maintaining their professional standing in the industry.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A46 CPCS Piling Rig Driven Above 20 Tonnes course is a
              comprehensive training program aimed at enhancing the skills and
              employability of aspiring and experienced piling rig operators.
            </p>
            <br />
            <p>
              Whether you are entering the construction industry or seeking to
              validate your skills with recognized certification, this course
              provides a robust platform for career growth. Participants will
              benefit from expert-led training, practical hands-on experience,
              and industry-aligned assessments.
            </p>
            <br />
            <p>
              Secure your spot today to advance your career in the construction
              sector and contribute to safer and more efficient work
              environments.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
