import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigBoredAbove20Tonnes() {
  return (
    <>
      <Helmet>
        <title>A48 Piling Rig Bored Above 20T | Alpha Construction</title>
        <meta
          name="description"
          content="Join our CPCS Piling Rig – Bored Above 20 Tonnes A48 Training Course to master piling rig operations, focusing on rigs bored above 20 tonnes, with certification upon completion."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A48 Piling Rig Bored Above 20T</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embark on a comprehensive training journey designed to equip
              participants with the knowledge and skills required to safely and
              efficiently operate piling rigs bored above 20 tonnes. This course
              emphasizes operational precision, safety, and compliance with
              industry standards. By the end, participants will be prepared to
              handle these complex machines with confidence, ensuring efficiency
              and safety on-site.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the functions and limitations of piling rigs.
              </li>
              <li>
                Mastering the roles and responsibilities of a piling rig
                operator, including adherence to site safety regulations.
              </li>
              <li>
                Learning the operational differences between various types of
                piling rigs, including bored, driven, and rotary rigs.
              </li>
              <li>
                Conducting thorough pre-use inspections to identify and mitigate
                potential risks.
              </li>
              <li>
                Configuring piling rigs for both travel and operational use,
                including setting up for drilling tasks.
              </li>
              <li>
                Identifying and troubleshooting common operational issues to
                minimize downtime.
              </li>
              <li>
                Gaining proficiency in coordinating with site teams to ensure
                smooth and safe operations.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes both written and practical assessments to
              evaluate participants’ theoretical knowledge and hands-on
              capabilities. The theoretical assessment focuses on safety
              protocols, machinery configuration, and legislative requirements,
              while the practical assessment allows participants to demonstrate
              their ability to safely operate piling rigs in real-world
              scenarios.
            </p>
            <br />
            <p>
              Participants will be assessed on their ability to perform pre-use
              checks, configure the rig for operations, and execute various
              operational tasks, including drilling, maneuvering, and shutting
              down the machinery effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the course and passing the
              assessments, participants will receive the CPCS A48 certification.
              This credential is widely recognized in the construction industry
              and serves as evidence of the holder's expertise in operating
              piling rigs bored above 20 tonnes.
            </p>
            <br />
            <p>
              Certification enhances employability by demonstrating a commitment
              to safety, proficiency in machinery operation, and compliance with
              industry standards. Many employers require this certification for
              operators working with heavy machinery.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig – Bored Above 20 Tonnes A48 Course is designed
              for individuals looking to advance their skills in heavy machinery
              operation, particularly in the use of advanced piling rigs. The
              course covers critical aspects such as safety protocols, machinery
              setup, and operational techniques.
            </p>
            <br />
            <p>
              Participants will gain in-depth knowledge of the technical and
              safety aspects of operating piling rigs. The training is tailored
              to meet the demands of the construction industry, preparing
              operators to excel in dynamic work environments.
            </p>
            <br />
            <p>
              By the end of the course, participants will be equipped to manage
              the challenges associated with large-scale piling projects,
              ensuring precision, safety, and compliance with industry
              standards.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
