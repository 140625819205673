import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigBoredBelow20Tonnes() {
  return (
    <>
      <Helmet>
        <title>
          A47 Piling Rig Bored Below 20 Tonnes | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in the CPCS Piling Rig Bored Below 20 Tonnes A47 training course at Alpha Construction Training to gain essential skills for operating piling rigs safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A47 Piling Rig Bored Below 20 Tonnes</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Piling Rig Bored Below 20 Tonnes A47 CPCS training course is
              meticulously crafted to provide participants with the knowledge,
              practical skills, and confidence required to operate piling rigs
              efficiently in various construction environments. This course aims
              to ensure that operators understand the importance of safety,
              compliance with industry regulations, and the technical aspects of
              piling operations, enabling them to contribute effectively to
              complex construction projects.
            </p>
            <br />
            <p>
              Whether you are a novice seeking to enter the industry or an
              experienced professional aiming to upgrade your skills, this
              training course ensures you meet the standards expected in the
              modern construction industry.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain a comprehensive understanding of the construction industry
                sector, focusing on the role and responsibilities of a piling
                rig operator.
              </li>
              <li>
                Explore the principal components of piling rigs, including their
                design, functionality, and operational limits, ensuring a solid
                foundation in equipment knowledge.
              </li>
              <li>
                Develop expertise in interpreting manufacturers' guidelines and
                adhering to industry regulations, including health and safety
                protocols.
              </li>
              <li>
                Conduct thorough pre-use checks to identify potential faults or
                hazards, ensuring equipment is safe and fit for operation.
              </li>
              <li>
                Learn to navigate rough and uneven terrain, understanding the
                challenges posed by various site conditions and how to overcome
                them safely.
              </li>
              <li>
                Enhance skills in maneuvering piling rigs in confined spaces,
                prioritizing precision and safety in restricted environments.
              </li>
              <li>
                Master the setup, configuration, and operation of piling rigs
                for bored works, including the use of advanced controls and
                techniques.
              </li>
              <li>
                Identify and assess potential hazards such as underground and
                overhead services, implementing strategies to mitigate risks.
              </li>
              <li>
                Learn the processes involved in installing displacement piles,
                including positioning, drilling, and securing piles to exact
                specifications.
              </li>
              <li>
                Understand communication protocols, including the use of signals
                and radios, to ensure seamless coordination during operations.
              </li>
              <li>
                Gain insights into the legal and practical aspects of lifting
                operations, including load limits, stability, and safety
                measures.
              </li>
              <li>
                Practice de-rigging and securing equipment for transportation,
                ensuring compliance with loading and unloading procedures.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The assessment for this course is designed to comprehensively
              evaluate both theoretical knowledge and practical competence.
              Participants will undergo a combination of classroom-based
              instruction and hands-on exercises, culminating in the CPCS
              technical test. This test includes:
            </p>
            <br />
            <ul>
              <li>
                A theoretical examination to assess understanding of safety
                regulations, equipment functionality, and industry standards.
              </li>
              <li>
                A practical assessment to demonstrate proficiency in operating
                the piling rig, including pre-use checks, maneuvering, and
                completing operational tasks.
              </li>
            </ul>
            <br />
            <p>
              Feedback will be provided to help participants understand areas
              for improvement and reinforce their strengths, ensuring they are
              fully prepared for their professional responsibilities.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and passing the CPCS
              technical test, candidates will be awarded the Red CPCS Trained
              Operator Card. This certification is valid for two years and
              demonstrates the holder's capability to operate piling rigs below
              20 tonnes safely and effectively.
            </p>
            <br />
            <p>
              To upgrade to the Blue CPCS Competence Card, participants must
              complete an NVQ Level 2 in Plant Operations within the two-year
              timeframe. The Blue Card is a permanent certification, renewable
              every five years, and signifies a high level of competence in
              piling rig operations.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig Bored Below 20 Tonnes A47 course provides
              comprehensive training for individuals seeking to specialize in
              piling operations. Covering both theoretical and practical
              aspects, this course ensures participants are well-prepared to
              meet the demands of the industry.
            </p>
            <br />
            <p>
              Participants will leave the course equipped with the skills to
              safely operate piling rigs, perform essential maintenance, and
              comply with all relevant regulations. This training not only
              enhances employability but also fosters professional growth and
              career advancement in the construction sector.
            </p>
            <br />
            <p>
              Whether you’re starting your journey in plant operations or
              looking to refine your expertise, this course is a vital step
              toward achieving industry-recognized certification and excelling
              in your role.
            </p>
            <br />
            <p>
              Take the next step in your career with Alpha Construction
              Training. Enroll today and unlock new opportunities in the dynamic
              field of piling rig operations.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
