import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDumpTruckArticulated() {
  return (
    <>
      <Helmet>
        <title>A56 Dump Truck Articulated | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in our CPCS Dump Truck Training course to gain the skills and certification needed for operating dump trucks with articulated chassis."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A56 Dump Truck Articulated</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is tailored to provide comprehensive
              training for individuals aspiring to operate dump trucks with
              articulated chassis proficiently. The course is suitable for
              newcomers to the industry as well as experienced operators seeking
              to formalize their skills and qualifications. Participants will
              gain in-depth knowledge of the machine's capabilities,
              limitations, and practical application on-site, ensuring they are
              well-prepared for various operational challenges.
            </p>
            <br />
            <p>
              The training emphasizes both technical skill development and
              safety awareness, fostering the competence and confidence required
              for efficient dump truck operation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Conducting pre-start checks to ensure the machine is safe and
                ready for operation.
              </li>
              <li>
                Understanding and mitigating risks associated with quick hitch
                devices and other attachments.
              </li>
              <li>
                Operating the dump truck efficiently under varying site
                conditions, including challenging terrains and restricted areas.
              </li>
              <li>
                Effective handling and use of ancillary equipment to maximize
                productivity.
              </li>
              <li>
                Siting and positioning the machine safely and effectively to
                support construction operations.
              </li>
              <li>
                Carrying out routine servicing procedures and making minor
                adjustments to maintain optimal performance.
              </li>
              <li>
                Preparing the machine for safe travel on public highways,
                adhering to all legal requirements and regulations.
              </li>
              <li>
                Understanding the operational principles of diesel engines and
                other key components of the dump truck.
              </li>
              <li>
                Maintaining clean, safe, and efficient working environments on
                the construction site.
              </li>
              <li>
                Recognizing and responding to potential hazards while operating
                the dump truck.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              Participants will be evaluated through a blend of theoretical
              knowledge checks and practical assessments. The assessment process
              includes:
            </p>
            <br />
            <ul>
              <li>
                A written or verbal theory test to measure understanding of
                machine operation, safety procedures, and site protocols.
              </li>
              <li>
                Practical tests where participants demonstrate their ability to
                operate the articulated dump truck effectively, including tasks
                like loading, unloading, and maneuvering in tight spaces.
              </li>
              <li>
                Observations of participants’ adherence to safety standards and
                environmental regulations.
              </li>
            </ul>
            <br />
            <p>
              Successful completion of these assessments leads to eligibility
              for CPCS certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon passing the CPCS technical tests, participants will be
              awarded the CPCS Red Trained Operator Card, which is valid for two
              years. This card signifies that the holder has successfully
              demonstrated the skills and knowledge required to operate dump
              trucks with articulated chassis safely and efficiently.
            </p>
            <br />
            <p>
              To progress to the CPCS Blue Competence Card, participants must
              complete an NVQ Level 2 in Plant Operations. This qualification
              validates the operator's practical competence and is a mandatory
              step for individuals seeking long-term recognition in the
              industry.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Dump Truck – Articulated Chassis Training Course is
              designed to provide a comprehensive foundation in articulated dump
              truck operation. With a balance of theoretical instruction and
              hands-on practice, this course equips participants with the skills
              and knowledge necessary to excel in their roles. Whether you are
              just starting in the industry or aiming to enhance your expertise,
              this training is an essential step toward professional growth and
              industry accreditation.
            </p>
            <br />
            <p>
              Join Alpha Construction Training and take the next step in your
              career by achieving an industry-recognized certification for
              operating articulated chassis dump trucks.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
